import React from 'react';
import SliderReview from 'react-slick';
import styled from 'styled-components';
import { OrangeBtn } from '../../../../newComponents/Button/ButtonSkin';
import { Link } from 'react-router-dom';

import borderImg from '../../../../assets/newAssets/title-border-small.png';

import bgGreenTexture from '../../../../assets/newAssets/bg-texture-green.jpg';

import testimonial_img1 from '../../../../assets/newAssets/testimonials/herrera.jpg';
import testimonial_img2 from '../../../../assets/newAssets/testimonials/willy.jpg';
import testimonial_img3 from '../../../../assets/newAssets/testimonials/claudia.jpg';

const VidTestimonial = styled.section`
    background: #004532 url(${({ bgGreenTexture }) => bgGreenTexture}) no-repeat center top;
    background-size: cover;
    padding: 90px 4.8rem 93px;
    text-align: center;

    .two-cols {
        max-width: 100%;
        flex: 1;
        gap: 80px;
        .testimonal-slider {
            box-shadow: 2px 4px 15px rgba(0, 0, 0, 0.05);
            border-radius: 8px;
            width: 100%;
            max-width: 480px;
            .slide-box {
                border-radius: 8px;
                background-color: white;
                padding: 40px 66px 66px;
                box-sizing: border-box;
                border-radius: 8px;

                .profile-info-parent {
                    gap: 18px;
                    .profile-img {
                        text-align: center;
                        img {
                            border-radius: 50%;
                            max-width: 160px;
                            border: 3px solid #6ea44c;
                        }
                    }
                    .profile-info-quote {
                        font-size: 19px;
                        line-height: 1.3;
                        color: ${({ theme }) => theme.colors.title_green};
                        font-weight: 600;
                        gap: 5px;
                        .profile-name {
                            font-size: 16px;
                            font-weight: 400;
                            line-height: 1.8;
                            color: ${({ theme }) => theme.colors.grey};
                        }
                    }
                }
            }
        }
        .video-section {
            video {
                width: 100%;
                max-width: 596px;
                height: auto;
                border-radius: 8px;
            }
        }
        .testimonal-information {
            gap: 12px 0;
            text-align: left;
            max-width: 330px;
            h3.white-text {
                font-size: 48px;
                line-height: 58px;
                font-weight: 600;
                color: white;
                .border {
                    margin: 0 10px;
                    img {
                        left: 2px;
                        bottom: -2px;
                    }
                }
            }
            .parent-info {
                gap: 16px;
                .testimonial-text {
                    line-height: 180%;
                    color: white;
                }
            }
        }
    }
    @media (max-width: ${({ theme }) => theme.media.tab}) {
        padding: 24px 20px 80px;
        .two-cols {
            flex-wrap: wrap;
            gap: 32px;
            padding: 0;
            .testimonal-slider {
                max-width: none;
                order: 1;
                box-shadow: none;
                .slick-slider {
                    margin-left: -20px;
                    margin-right: -20px;
                    .slick-dots {
                        bottom: -45px;
                    }
                }
                .slick-slide > div {
                    margin: 0 8px;
                }
                .slide-box {
                    box-shadow: 2px 4px 15px rgba(0, 0, 0, 0.05);
                    padding: 20px 16px 20px;
                    .profile-info-parent {
                        //display: inline-block;
                        .profile-img {
                            margin-bottom: 8px;
                        }
                        .profile-info-quote {
                            display: inline-block;
                            .profile-quote {
                                margin-bottom: 4px;
                            }
                        }
                    }
                }
                .slick-center {
                    .profile-name {
                        margin-top: 6px;
                    }
                }
            }
            .video-section {
                margin-top: -50px;
                video {
                    width: 100%;
                }
            }
            .testimonal-information {
                order: 0;
                text-align: left;
                gap: 8px;
                max-width: max-content;
                h3.white-text {
                    font-size: 28px;
                    line-height: 38px;
                }
                .parent-info {
                    gap: 12px;
                }
            }
        }
    }
`;
const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,

    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                centerMode: true,
                className: 'center',
                arrows: false,
                dots: true,
                speed: 300,
                centerPadding: '35px',
                infinite: true,
            },
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                centerMode: true,
                className: 'center',
                arrows: false,
                dots: true,
                speed: 300,
                centerPadding: '35px',
                infinite: true,
                adaptiveHeight: true,
            },
        },
    ],
};
const FriendTestimonialContent = () => {
    const testimonialData = [
        {
            id: 1,
            profileImg: testimonial_img1,
            profileQuote:
                '"By offsetting my footprint with Regenera I directly support the conservation of Nature in Peru. I really feel I am doing something to help save the planet."',
            profileName: 'Alejandra Herrera',
            profilePost: 'Student',
        },
        {
            id: 2,
            profileImg: testimonial_img2,
            profileQuote:
                '"With Regenera I support the partnership of Andean and Amazonian forests in Peru, by compensating my footprint and supporting the people that live in those landscapes."',
            profileName: 'Guillermo Reaño',
            profilePost: 'Journalist',
        },
        {
            id: 3,
            profileImg: testimonial_img3,
            profileQuote:
                '"Through a modest contribution, Regenera allows me to have a positive environmental and social impact, protecting one of my favourite landscapes in Peru."',
            profileName: 'Claudia Palomino',
            profilePost: 'Sustainability Specialist',
        },
    ];

    return (
        <VidTestimonial bgGreenTexture={bgGreenTexture}>
            <div className="two-cols flex justify-center items-center">
                <div className="testimonal-information flex flex-col items-start">
                    <h3 className="white-text">
                        What our
                        <span className="border relative">
                            Friends
                            <img src={borderImg} className="absolute w-full" alt="border" />
                        </span>
                        say about us
                    </h3>

                    <div className="parent-info flex flex-col">
                        <div className="testimonial-text">
                            Like what you see? You too can drive positive change for people and
                            planet.
                        </div>
                        <div className="button-box">
                            <OrangeBtn>
                                <Link to="/signup">Become a Friend</Link>
                            </OrangeBtn>
                        </div>
                    </div>
                </div>

                <div className="testimonal-slider">
                    <SliderReview {...settings}>
                        {testimonialData.map(item => (
                            <div className="slide-box">
                                <div className="profile-info-parent flex flex-col">
                                    <div className="profile-img mx-auto">
                                        <img alt="profile img" src={item.profileImg} />
                                    </div>
                                    <div className="profile-info-quote flex flex-col">
                                        <div className="profile-quote">{item.profileQuote}</div>
                                        <div className="profile-name">
                                            <strong>{item.profileName}</strong> - {item.profilePost}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </SliderReview>
                </div>
            </div>
        </VidTestimonial>
    );
};

export default FriendTestimonialContent;
