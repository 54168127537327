import styled from 'styled-components';

export const FriendSection = styled.section`
    .green-bg {
        .text-white,
        .text-white p {
            br {
                display: none;
            }
        }
    }
    .two-cols {
        justify-content: space-between;
        padding: 0 100px;
        margin: 0 auto;
    }

    .center-text-btn {
        margin-top: 30px;
        gap: 0;
        button {
            max-width: 250px;
            margin: 20px auto 0;
        }
    }

    .panel-link {
        display: block;
        margin: 24px 0 48px;
        font-weight: 600;
        color: ${({ theme }) => theme.colors.light_green};
        text-decoration: underline;
        &:hover {
            text-decoration: none;
        }
    }

    @media (max-width: ${({ theme }) => theme.media.tab}) {
        .center-text-btn {
            p {
                font-size: 16px;
                margin-top: 5px;
            }
            button {
                margin-top: 12px;
            }
        }
    }
    @media (max-width: ${({ theme }) => theme.media.mobile}) {
    }
`;
