import React from 'react';
import styled from 'styled-components';
import { LightGreenBtn } from '../newComponents/Button/ButtonSkin';
import listIcon from '../assets/newAssets/icons/list-icon.svg';
import { FaSpinner } from 'react-icons/fa';

const PlanBox = styled.div`
    padding: 40px;
    border-radius: 8px;
    box-shadow: 2px 4px 15px 0px rgba(0, 0, 0, 0.05);
    .plan-box {
        max-width: 415px;
        width: 100%;
        border: 2px solid #f3f2f2;
        &:hover {
            border: 2px solid #6ea44c;
        }
    }
    .plan-intro {
        text-align: center;
        img {
            max-height: 56px;
        }
        .plan-name {
            margin-top: 12px;
            font-size: 19px;
            font-weight: 600;
            line-height: 27px;
        }
        .plan-info {
            line-height: 150%;
            font-weight: 300;
            min-height: 100px;
        }
    }
    .plan-cost {
        text-align: center;
        font-size: 32px;
        font-weight: 500;
        line-height: 42px;
        padding: 12px 0;
        span {
            font-size: 19px;
            line-height: 27px;
        }
    }
    .feature-boxes {
        .feature {
            padding: 12px 0;
            border-top: 1px solid #f6f6f6;
            .feature-title {
                font-size: 19px;
                line-height: 27px;
                font-weight: 500;
            }
            .short {
                line-height: 150%;
                font-weight: 300;
            }
            .list {
                margin-top: 8px;
                li {
                    line-height: 180%;
                    margin-top: 4px;
                    position: relative;
                    padding-left: 32px;
                    &::before {
                        position: absolute;
                        content: '';
                        background: url(${({ listIcon }) => listIcon}) no-repeat center center;
                        width: 20px;
                        height: 21px;
                        top: 5px;
                        left: 0;
                    }
                }
            }
        }
    }
    .action {
        margin-top: auto;
        button {
            width: 100%;
        }
    }
    .spinner {
        animation: spin infinite 2s linear;
        margin-right: 8px;
        /*You can increase or decrease the timer (5s) to 
       increase or decrease the speed of the spinner*/
    }
    @media (max-width: ${({ theme }) => theme.media.tab}) {
        padding: 16px;
        .plan-intro {
            text-align: left;
            .plan-name {
                margin-top: 8px;
                font-size: 16px;
                font-weight: 500;
                line-height: 24px;
            }
            .plan-info {
                min-height: auto;
            }
        }
        .plan-cost {
            font-size: 28px;
            font-weight: 500;
            line-height: 38px;
            padding: 8px 0;
        }
        .feature-boxes {
            .feature {
                padding: 8px 0;
                .feature-title {
                    font-size: 17px;
                    line-height: 24px;
                    font-weight: 500;
                }
            }
        }
        .action {
            margin-top: 30px;
        }
    }
`;

const PlanBoxOption = ({
    planBoxClass,
    planImg,
    planName,
    planInfo,
    planCost,
    planduration,
    actionLinkText,
    data,
    plan,
    handlePlanSelection,
    isLoading,
}) => {
    return (
        <PlanBox className={`flex flex-col ${planBoxClass}`} listIcon={listIcon}>
            <div className="plan-intro">
                <img src={planImg} alt={planName} />
                <div className="plan-name">{planName}</div>
                <div className="plan-info">
                    <small>{planInfo}</small>
                </div>
            </div>
            <div className="plan-cost">
                {planCost}
                <span>{planduration}</span>
            </div>
            <div className="feature-boxes">
                {data.map(item => (
                    <div className="feature">
                        {item.featureTitle && (
                            <>
                                <div className="feature-title">{item.featureTitle}</div>
                                <div className="short">
                                    <small>{item.featureShort}</small>
                                </div>
                            </>
                        )}
                        <ul className="list">
                            {item.featureList &&
                                item.featureList.map(innritem => {
                                    return <li dangerouslySetInnerHTML={{ __html: innritem }}></li>;
                                })}

                            {item.impactIc && (
                                <li className="flex items-center">
                                    <div className="img-box flex justify-center items-center">
                                        <img src={item.impactIc} alt={item.listTxt} />
                                    </div>
                                    <span className="list-txt small">{item.listTxt}</span>
                                </li>
                            )}
                        </ul>
                    </div>
                ))}
            </div>
            <div className="action text-center">
                <LightGreenBtn onClick={() => handlePlanSelection(plan)} disabled={isLoading}>
                    {isLoading && <FaSpinner className="spinner" />}
                    {actionLinkText}
                </LightGreenBtn>
            </div>
        </PlanBox>
    );
};

export default PlanBoxOption;
