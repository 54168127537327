import React from 'react';
import { Link } from 'react-router-dom';

import Header from '../../newPages/NewLandingPage/Sections/Header/Header';
import Footer from '../../newPages/NewLandingPage/Sections/Footer/Footer';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Page } from '../../components';
import ImageWithText from '../../newComponents/ImageWithText';
import { OrangeBtn } from '../../newComponents/Button/ButtonSkin';

import HeroSection from '../../newComponents/HeroSection/HeroSection';
import partnersHeroDesktop from '../../assets/newAssets/partners/partners-hero-d.webp';
import partnersHeroMobile from '../../assets/newAssets/partners/partners-hero-m.webp';
import imageDesktop from '../../assets/newAssets/partners/nature-business-d.webp';
import imageMobile from '../../assets/newAssets/partners/nature-business-m.webp';
import bgGreenTexture from '../../assets/newAssets/bg-texture-green.jpg';

import leftLeaf from '../../assets/newAssets/left-leaf.webp';
import rightLeaf from '../../assets/newAssets/right-leaf.webp';

import WhyJoinMission from './Sections/WhyJoinMission';
import PartnerTestimonialContent from './Sections/PartnerTestimonials';
import HowitWorks from './Sections/HowItWorks';
import PartnerCompanies from './Sections/PartnerCompanies';
import ContactAction from './Sections/ContactAction';
import PlansComparison from '../../newComponents/PlansComparison';
import { PartnerSection } from './Styles';
import styled from 'styled-components';

const paras = [
    'Our team enables your organisation to have a positive impact on nature, climate, and communities. This is achieved through our partnerships with local land managers who receive technical assistance and monthly financial support to protect and restore natural landscapes.',
];
const pragraph = paras.map(para => <p>{para}</p>);

const PlanContainer = styled.section`
    padding: 78px 4.8rem 110px;
    position: relative;
    &::before,
    &::after {
        content: '';
        position: absolute;
        width: 168px;
        height: 351px;
    }
    &::before {
        background: url(${({ leftLeaf }) => leftLeaf}) left top no-repeat;
        left: 0;
        top: -100px;
    }
    &::after {
        background: url(${({ rightLeaf }) => rightLeaf}) left top no-repeat;
        right: 0;
        top: -100px;
    }
    .title-box {
        h2 {
            font-size: 3rem;
            line-height: 58px;
        }
        margin-bottom: 60px;
    }
    .plan-group {
        gap: 20px;
        .plan-box {
            max-width: 415px;
            width: 100%;
            border: 2px solid #f3f2f2;
            &:hover {
                border: 2px solid #6ea44c;
            }
        }
        .plan-box-active {
            border: 2px solid #6ea44c;
        }
    }

    @media (max-width: 1175px) {
        padding: 32px 20px 50px;
        &::before,
        &::after {
            content: none;
        }
    }
    @media (max-width: ${({ theme }) => theme.media.mobile}) {
        .plan-group {
            flex-wrap: wrap;
            gap: 10px;
        }
    }
`;

const PartnersPage = ({ location, history, user }) => {
    const planTarget = 'empresa';
    return (
        <Page>
            <Header currentPath={location.pathname} user={user} history={history} />
            <PartnerSection bgGreenTexture={bgGreenTexture}>
                <HeroSection
                    title="We can't regenerate Nature without your"
                    titleline="support"
                    titleclass="text-white"
                    paratext={pragraph}
                    paraclass="text-white"
                    heroBg="green-bg"
                    heroImgM={partnersHeroMobile}
                    heroImgD={partnersHeroDesktop}
                    alttxt="Partners"
                    orangeBtn
                    btnText="Sign up"
                    btnLink="/signup"
                />
                <WhyJoinMission history={history} />
                <PartnerTestimonialContent />
                <HowitWorks />
                <PlanContainer leftLeaf={leftLeaf} rightLeaf={rightLeaf}>
                    <div className="title-box text-center">
                        <h2>
                            Let's regenerate
                            <br />
                            Nature together!
                        </h2>
                    </div>
                    <div className="plan-group flex justify-center">
                        <PlansComparison user={user} userType={planTarget} />
                    </div>

                    <ContactAction />
                </PlanContainer>
                <ImageWithText
                    section="business-nature"
                    imageDesktop={imageDesktop}
                    imageMobile={imageMobile}
                    imgWithClass="nature-img"
                    contentBox="button-box"
                    imgBoxClass="picture-box"
                    firstTitle="Make Nature your business today!"
                    text={
                        <OrangeBtn>
                            <Link to="/signup">Join now</Link>
                        </OrangeBtn>
                    }
                />
                <PartnerCompanies />
            </PartnerSection>
            <Footer history={history} />
        </Page>
    );
};

const mapStateToProps = storeState => ({
    user: storeState.user.currentUser
        ? {
              initials: storeState.user.currentUser.attributes.profile.abbreviatedName,
              name:
                  storeState.user.currentUser.attributes.profile.firstName +
                  ' ' +
                  storeState.user.currentUser.attributes.profile.lastName,
              email: storeState.user.currentUser.attributes.email,
              firstName: storeState.user.currentUser.attributes.profile.firstName,
              lastName: storeState.user.currentUser.attributes.profile.lastName,
              currentRole: storeState.user.currentUser.currentRole,
              cartCount: storeState.user.currentUser.cartCount,
          }
        : null,
});
export default connect(mapStateToProps)(withRouter(PartnersPage));
