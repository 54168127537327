import React from 'react';
import { Link } from 'react-router-dom';
import { OrangeBtn } from '../../newComponents/Button/ButtonSkin';
import titleBorderLine from '../../assets/newAssets/title-border-line.svg';
import { HeroContainer, LeftSection, RightSection } from './Styles';
import bgGreenTexture from '../../assets/newAssets/bg-texture-green.jpg';

const HeroSection = ({
    title,
    titleline,
    titleafter,
    titleclass,
    paratext,
    paraclass,
    heroBg,
    heroImgM,
    heroImgD,
    alttxt,
    orangeBtn,
    btnText,
    btnLink,
}) => {
    return (
        <HeroContainer
            bgGreenTexture={bgGreenTexture}
            className={`flex items-center justify-space-between ${heroBg}`}
        >
            <LeftSection>
                <div className="title-box">
                    <h1 className={titleclass}>
                        {title} <br />
                        <span className="border">
                            {titleline}
                            <img src={`${titleBorderLine}`} alt="border" />
                        </span>
                        &nbsp;{titleafter}
                    </h1>
                </div>
                <div className={`content-box ${paraclass}`}>{paratext}</div>
                {orangeBtn && (
                    <OrangeBtn className="cta">
                        <Link to={btnLink}>{btnText}</Link>
                    </OrangeBtn>
                )}
            </LeftSection>

            <RightSection className="right-section">
                <picture>
                    <source media="(max-width:768px)" srcset={heroImgM} />
                    <img src={heroImgD} className="w-full" alt={alttxt} />
                </picture>
            </RightSection>
        </HeroContainer>
    );
};

export default HeroSection;
