import React from 'react';
import { WhyJoinMissionSection } from './Styles';
import IconWithText from '../../../../newComponents/IconWithText';
import planChooseIcon from '../../../../assets/newAssets/icons/plan-choose-icon.svg';
import handPlantIcon from '../../../../assets/newAssets/icons/hand-plant-icon.svg';
import leadIcon from '../../../../assets/newAssets/icons/lead_icon.svg';
import heroLeaf from '../../../../assets/newAssets/hero-leaf.png';

const WhyJoinMission = () => {
    return (
        <WhyJoinMissionSection heroLeaf={heroLeaf}>
            <div className="take-action-group flex justify-start flex-col mx-auto">
                <div className="title-box">
                    <h2>Reasons why companies like yours joined our mission</h2>
                </div>
                <div className="icon-text-container flex justify-space-between flex-row items-start">
                    <IconWithText
                        icon={handPlantIcon}
                        title="Become part of the solution by truly making an impact"
                        text="Become part of a community that empowers us all to care for the places and Nature we love."
                    />
                    <IconWithText
                        icon={planChooseIcon}
                        title="Receive practical expert advice on your path to Net Zero"
                        text="Our experts share their knowledge and help you craft your way to reducing your company's footprint."
                    />
                    <IconWithText
                        icon={leadIcon}
                        title="Lead for greater impact"
                        text="Become a leader of the future by sharing your progress and setting an example for others."
                    />
                </div>
            </div>
        </WhyJoinMissionSection>
    );
};

export default WhyJoinMission;
