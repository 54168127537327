import pollen from '../assets/newAssets/icons/pollen.svg';
import landscape from '../assets/newAssets/icons/landscape.svg';
import seedling from '../assets/newAssets/icons/leaf.svg';
import tree from '../assets/newAssets/icons/tree.svg';
import forest from '../assets/newAssets/icons/forest.svg';

import { FormattedMessage, injectIntl } from 'react-intl';
import React from 'react';

export const plans = [
    {
        id: 0,
        planTitle: <FormattedMessage id="ProductComparison.people.pollen.planName" />,
        planDescription: <FormattedMessage id="ProductComparison.people.pollen.description" />,
        planCost: 600,
        landProtected: 1000,
        emissionComp: 1000,
        treePlanted: 1,
        planImage: pollen,
        plan: 'polen',
        planType: 'polen_price',
        planTarget: 'personas',
        featureList: [
            {
                featureList: [
                    'Footprint reports & status in platform',
                    'Monthly updates from your landscape',
                    'Digital trimestral impact reports',
                    '<b>1,000</b> m2 of land protected',
                    '<b>1,000</b> kg of CO2 emissions compensated per year',
                    '<b>1</b> Tree planted and cared for per year',
                ],
            },
        ],
        isRecommended: false,
    },
    {
        id: 1,
        planTitle: <FormattedMessage id="ProductComparison.people.seed.planName" />,
        planDescription: <FormattedMessage id="ProductComparison.people.seed.description" />,
        planCost: 1500,
        landProtected: 5000,
        emissionComp: 5000,
        treePlanted: 1,
        planImage: seedling,
        plan: 'seed',
        planType: 'semilla',
        planTarget: 'personas',
        featureList: [
            {
                featureList: [
                    'Footprint reports & status in platform',
                    'Monthly updates from your landscape',
                    'Digital trimestral impact reports',
                    '<b>5,000</b> m2 of land protected',
                    '<b>5,000</b> kg of CO2 emissions compensated per year',
                    '<b>1</b> Tree planted and cared for per year',
                ],
            },
        ],
        isRecommended: true,
    },
    {
        id: 2,
        planTitle: <FormattedMessage id="ProductComparison.people.tree.planName" />,
        planDescription: <FormattedMessage id="ProductComparison.people.tree.description" />,
        planCost: 3000,
        landProtected: 10000,
        emissionComp: 10000,
        treePlanted: 2,
        planImage: tree,
        plan: 'tree',
        planType: 'arbol',
        planTarget: 'personas',
        featureList: [
            {
                featureList: [
                    'Footprint reports & status in platform',
                    'Monthly updates from your landscape',
                    'Digital trimestral impact reports',
                    '<b>10,000</b> m2 of land protected',
                    '<b>10,000</b> kg of CO2 emissions compensated per year',
                    '<b>2</b> Trees planted and cared for per year',
                ],
            },
        ],
        isRecommended: false,
    },
    {
        id: 3,
        planTitle: <FormattedMessage id="ProductComparison.people.forest.planName" />,
        planDescription: <FormattedMessage id="ProductComparison.people.forest.description" />,
        planCost: 7500,
        landProtected: 25000,
        emissionComp: 25000,
        treePlanted: 4,
        planImage: forest,
        plan: 'forest',
        planType: 'bosque',
        planTarget: 'familia',
        featureList: [
            {
                featureList: [
                    'Footprint reports & status in platform',
                    'Monthly updates from your landscape',
                    'Digital trimestral impact reports',
                    '<b>25,000</b> m2 of land protected',
                    '<b>25,000</b> kg of CO2 emissions compensated per year',
                    '<b>4</b> Trees planted and cared for per year',
                ],
            },
        ],
        isRecommended: false,
    },
    {
        id: 4,
        planTitle: <FormattedMessage id="ProductComparison.people.landscape.planName" />,
        planDescription: <FormattedMessage id="ProductComparison.people.landscape.description" />,
        planCost: 30000,
        landProtected: 100000,
        emissionComp: 100000,
        treePlanted: 10,
        planImage: landscape,
        plan: 'landscape',
        planType: 'paisaje',
        planTarget: 'familia',
        featureList: [
            {
                featureList: [
                    'Footprint reports & status in platform',
                    'Monthly updates from your landscape',
                    'Digital trimestral impact reports',
                    '<b>100,000</b> m2 of land protected',
                    '<b>100,0000</b> kg of CO2 emissions compensated per year',
                    '<b>10</b> Trees planted and cared for per year',
                ],
            },
        ],
        isRecommended: false,
    },
    {
        id: 5,
        planTitle: <FormattedMessage id="ProductComparison.business.seed.planName" />,
        planDescription: <FormattedMessage id="ProductComparison.business.seed.description" />,
        planCost: 9000,
        landProtected: 25000,
        emissionComp: 25000,
        treePlanted: 4,
        planImage: seedling,
        plan: 'seed',
        planType: 'semillaEmpresas',
        planTarget: 'empresa',
        featureList: [
            {
                featureTitle: 'Measure',
                featureShort: 'Use our tools to:',
                featureList: ['Calculate your footprint'],
            },
            {
                featureTitle: 'Improve',
                featureShort: 'We enable you to:',
                featureList: [
                    'Protect & restore <b>2.5</b> hectares',
                    'Defend endangered species',
                    'Compensate <b>20</b> tonnes GHG',
                    '<b>4</b> Trees planted and cared for per year',
                ],
            },
            {
                featureTitle: 'Lead',
                featureShort: 'Work with us towards your:',
                featureList: ['Nature positive objectives'],
            },
        ],
        isRecommended: false,
    },
    {
        id: 6,
        planTitle: <FormattedMessage id="ProductComparison.business.tree.planName" />,
        planDescription: <FormattedMessage id="ProductComparison.business.tree.description" />,
        planCost: 36000,
        landProtected: 100000,
        emissionComp: 100000,
        treePlanted: 10,
        planImage: tree,
        plan: 'tree',
        planType: 'arbolEmpresas',
        planTarget: 'empresa',
        featureList: [
            {
                featureTitle: 'Measure',
                featureShort: 'Use our tools to:',
                featureList: ['Evaluate your current status', 'Calculate your footprint'],
            },
            {
                featureTitle: 'Improve',
                featureShort: 'We enable you to:',
                featureList: [
                    'Protect & restore <b>10</b> hectares',
                    'Defend endangered species',
                    'Compensate <b>100</b> tonnes GHG',
                    '<b>10</b> Trees planted and cared for per year',
                    'Improve operational practices',
                ],
            },
            {
                featureTitle: 'Lead',
                featureShort: 'Work with us towards your:',
                featureList: ['Nature positive objectives'],
            },
        ],
        isRecommended: false,
    },
    {
        id: 7,
        planTitle: <FormattedMessage id="ProductComparison.business.forest.planName" />,
        planDescription: <FormattedMessage id="ProductComparison.business.forest.description" />,
        planCost: 90000,
        landProtected: 250000,
        emissionComp: 250000,
        treePlanted: 40,
        planImage: forest,
        plan: 'forest',
        planType: 'bosqueEmpresas',
        planTarget: 'empresa',
        featureList: [
            {
                featureTitle: 'Measure',
                featureShort: 'Use our tools to:',
                featureList: ['Evaluate your current status', 'Calculate your footprint'],
            },
            {
                featureTitle: 'Improve',
                featureShort: 'We enable you to:',
                featureList: [
                    'Protect & restore <b>25</b> hectares',
                    'Defend endangered species',
                    'Compensate <b>250</b> tonnes GHG',
                    '<b>40</b> Trees planted and cared for per year',
                    'Improve operational practices',
                ],
            },
            {
                featureTitle: 'Lead',
                featureShort: 'Work with us towards your:',
                featureList: ['Nature positive objectives', 'Impact & communication goals'],
            },
        ],
        isRecommended: false,
    },
];
