import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { IoMdArrowDropdown } from 'react-icons/io';

const CustomSelect = ({ options, handlePinClick, selectedPin }) => {
    console.log('selectedpin', selectedPin);
    const [isOptionsOpen, setIsOptionsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(selectedPin);

    useEffect(() => {
        selectedPin && setSelectedOption(selectedPin);
    }, [selectedPin]);

    const toggleOptions = () => {
        setIsOptionsOpen(!isOptionsOpen);
    };

    const setSelectedThenCloseDropdown = id => {
        setSelectedOption(id);
        setIsOptionsOpen(false);
        handlePinClick(id);
    };

    const CustomSelectBox = styled.div`
        position: relative;
        button {
            border-radius: 25px;
            border: 1px solid #ddedd0;
            width: 100%;
            padding: 10px 16px;
            position: relative;
            text-align: left;
            font-size: 1rem;
            color: ${({ theme }) => theme.colors.title_green};
            .drop-icon {
                right: 16px;
                position: absolute;
            }
        }
        .options {
            position: absolute;
            width: 100%;
            z-index: 2;
            border: 1px solid green;
            display: none;
            border-radius: 5px;
            list-style: none;
            padding: 4px 0;
            background-color: white;
            left: 0;
            right: 0;
            li {
                padding: 10px;
                font-size: 1rem;
                &:active,
                &:focus,
                &:hover,
                [aria-selected='true'] {
                    background: #eaeaea;
                    cursor: pointer;
                }
            }
        }
        .show {
            display: block;
            background-color: white;
        }
    `;

    console.log(
        'filtered:',
        selectedOption,
        options && selectedOption && options.filter(item => item.id === selectedOption)[0].name
    );
    return (
        <CustomSelectBox>
            <button
                type="button"
                aria-haspopup="listbox"
                aria-expanded={isOptionsOpen}
                className={isOptionsOpen ? 'expanded' : ''}
                onClick={toggleOptions}
            >
                {options &&
                    selectedOption &&
                    options.filter(item => item.id === selectedOption)[0].name}
                <IoMdArrowDropdown className="drop-icon" />
            </button>
            <ul
                className={`options ${isOptionsOpen ? 'show' : ''}`}
                role="listbox"
                aria-activedescendant={
                    options &&
                    selectedOption &&
                    options.filter(item => item.id === selectedOption)[0].name
                }
                tabIndex={-1}
            >
                {options &&
                    options.map((option, index) => (
                        <li
                            id={option.id}
                            role="option"
                            aria-selected={selectedOption === option.id}
                            tabIndex={0}
                            onClick={() => {
                                setSelectedThenCloseDropdown(option.id);
                            }}
                        >
                            {option.name}
                        </li>
                    ))}
            </ul>
        </CustomSelectBox>
    );
};

export default CustomSelect;
