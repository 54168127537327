import React from 'react';
import styles from './styles.module.css';
import { useEffect, useState, useRef } from 'react';
import Header from '../../../newPages/NewLandingPage/Sections/Header/Header';

import { FormattedMessage } from 'react-intl';

import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import parse from 'html-react-parser';
import { getBlogBySlug, getRelatedBlogsByTags, handleQueryLanguage } from '../utils';
import Footer from '../../../newPages/NewLandingPage/Sections/Footer/Footer';
import { IconSpinner } from '../../../components';

import BlogCard from '../../../newComponents/BlogCard';
import { v4 as uuidv4 } from 'uuid';
import BlogTag from '../../../newComponents/BlogTag';
import ShareBlog from '../../../newComponents/ShareBlog';

import { changeLanguage } from '../../../ducks/Languages.duck';
import RelatedBlogSection from './RelatedBlogSection';
import BecomePartner from './BecomePartner';
import { Page } from '../../../components';
import bgGreenTexture from '../../../assets/newAssets/bg-texture-green.jpg';
import { ShareBtn } from '../../../newComponents/Button/ButtonSkin';
import { BsFillShareFill } from 'react-icons/bs';
import styled from 'styled-components';

// styled

const StyledDropdown = styled.div`
    position: relative;
`;

const BlogPostSection = styled.section`
    background-color: #f8fbf6;
    position: relative;
    .bg-img {
        height: 490px;
        background: url(${({ bgGreenTexture }) => bgGreenTexture}) center top no-repeat;
    }
    .blog-detail-info {
        background: white;
        max-width: 1064px;
        margin-top: -450px;
        z-index: 1;

        .blog-detail-hero {
            padding: 24px 24px 0;
            .author-category {
                .author-section {
                    gap: 20px;
                    .author-img {
                        max-width: 90px;
                        max-height: 90px;
                        img {
                            width: 90px;
                            height: 90px;
                            border-radius: 50%;
                            border: 2.353px solid ${({ theme }) => theme.colors.light_green};
                        }
                    }

                    .author-detail {
                        .author-name {
                            line-height: 180%;
                            margin-bottom: 5px;
                            a {
                                color: ${({ theme }) => theme.colors.title_green};
                            }
                        }
                        .post-date {
                            line-height: 180%;
                            margin-right: 10px;
                        }
                        .time-to-read {
                            margin-left: 10px;
                        }
                    }
                }
                .taxonomy-list {
                    right: 55px;
                    top: 30px;
                }
            }
            .main-post-title {
                font-size: 48px;
                line-height: 58px;
                margin: 20px 0;
            }
            .blog-hero-image {
                margin-top: 30px;
                img {
                    object-fit: cover;
                    border-radius: 8px;
                }
            }
        }
        .tags-share {
            display: flex;
            line-height: 28px;
            justify-content: space-between;
            margin: 20px 25px;
            .share-link {
                gap: 5px;
            }
        }
        .post-content {
            padding: 10px 30px;

            .post-title {
                margin-top: 40px;
                margin-bottom: 20px;
            }
            .post-img {
                margin: 40px auto;
                border-radius: 8px;
            }

            .blogContentHtml {
                text-align: center;
            }

            .blogContentHtml h1 {
                text-align: left;
                margin: 0;
                margin: 15px 0;
            }
            .blogContentHtml h2 {
                text-align: left;
                margin-top: 40px;
                margin-bottom: 20px;
            }
            .blogContentHtml h3 {
                text-align: left;
                margin: 0;
                margin: 15px 0;
            }

            .blogContentHtml h4 {
                text-align: left;
                margin: 0;
                margin: 12px 0;
            }
            .blogContentHtml h5 {
                text-align: left;
                margin: 0;
                margin: 10px 0;
            }
            .blogContentHtml h6 {
                text-align: left;
                margin: 0;
                margin: 10px 0;
            }

            .blogContentHtml p {
                text-align: left;
                margin: 0;
                padding: 0;
                margin: 12px 0;
            }
            .blogContentHtml img {
                width: 100%;
                margin: 40px auto;
                border-radius: 8px;
            }
        }
    }
    @media (max-width: ${({ theme }) => theme.media.tab}) {
        .blog-detail-info {
            padding: 8px 14px 25px;
            margin: -65px 15px 20px;
            .author-category {
                .author-section {
                    gap: 12px;
                    .author-img {
                        max-width: 66px;
                        max-height: 66px;
                    }
                }
                .taxonomy-list {
                    right: 55px;
                    top: 30px;
                }
            }
            .post-content {
                margin-top: 20px;
                .main-post-title {
                    font-size: 40px;
                    line-height: 52px;
                    margin: 12px 0;
                }

                .blogContentHtml h2 {
                    margin-top: 20px;
                    margin-bottom: 12px;
                }

                .blogContentHtml img {
                    margin: 20px auto 10px;
                }
            }
        }
    }
`;

const BlogDetailPage = props => {
    const [display, setDisplay] = useState(false);
    const [blogDetails, setBlogDetails] = useState('');
    const [relatedBlogs, setRelatedBlogs] = useState([]);

    const shareRef = useRef(null);

    const newsSlug = props.params.newsSlug;
    const readTime = <FormattedMessage id="Blogs.detail.readTime" />;

    const handleOutsideClick = e => {
        if (shareRef.current && !shareRef.current.contains(e.target)) {
            setDisplay(false);
        }
    };

    const handleClickShare = () => {
        setDisplay(true);
        navigator.clipboard.writeText(window.location.href);
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [shareRef]);

    useEffect(() => {
        (async () => {
            const blogResponse = await getBlogBySlug(newsSlug);
            if (blogResponse.status === 'success') {
                console.log(blogResponse.data.locale.id.split('_')[0], props.language);
                if (props.initLanguage)
                    if (
                        props.language !== blogResponse.data.locale.id.split('_')[0].toUpperCase()
                    ) {
                        const searchIndex = blogResponse.data.translations.findIndex(
                            lang => lang.locale.split('_')[0].toUpperCase() === props.language
                        );
                        console.log(searchIndex);
                        setBlogDetails(blogResponse.data.translated[searchIndex]);
                        props.history.push(
                            `/news/${blogResponse.data.translated[searchIndex].slug}`,
                            {
                                shallow: true,
                            }
                        );
                    } else setBlogDetails(blogResponse.data);
                else {
                    console.log('initlang', props.initLanguage);
                    setBlogDetails(blogResponse.data);
                    props.changeLanguage(blogResponse.data.locale.id.split('_')[0].toUpperCase());
                }
            }
        })();
    }, [newsSlug]);

    useEffect(() => {
        (async () => {
            const queryLang = await handleQueryLanguage(props.language);
            if (blogDetails) {
                const tagSlugs = blogDetails.taxonomy.tags.map(tag => tag.slug);
                const relatedBlogs = await getRelatedBlogsByTags(queryLang, tagSlugs);
                const relatedBlogsFiltered = relatedBlogs.data
                    .filter(blog => blog.slug !== blogDetails.slug)
                    .slice(0, 3);
                if (relatedBlogs.status === 'success') {
                    setRelatedBlogs(relatedBlogsFiltered);
                }
            }
        })();
    }, [blogDetails]);

    return (
        <Page>
            <Header
                currentPath={props.location.pathname}
                user={props.user}
                history={props.history}
            />

            <BlogPostSection bgGreenTexture={bgGreenTexture}>
                {blogDetails ? (
                    <>
                        <div className="bg-img"></div>
                        <div className="blog-detail-info mx-auto relative">
                            <div className="blog-detail-hero">
                                <ul className="taxonomy-list flex justify-end hide-mobile">
                                    <li className="category-list">
                                        <Link
                                            to={`/news/category/${blogDetails.taxonomy.category.slug}`}
                                        >
                                            <small>{blogDetails.taxonomy.category.name}</small>
                                        </Link>
                                    </li>

                                    {blogDetails.taxonomy.landscape
                                        ? blogDetails.taxonomy.landscape.map(element => (
                                              <li className="landscape-list" key={element.id}>
                                                  <Link to={`/news/landscape/${element.slug}`}>
                                                      <small>{element.name}</small>
                                                  </Link>
                                              </li>
                                          ))
                                        : null}
                                </ul>
                                <div className="author-category">
                                    <div className="author-section flex items-center">
                                        <div className="author-img">
                                            <img
                                                src={blogDetails.writer.photo}
                                                className="w-full"
                                            />
                                        </div>
                                        <div className="author-detail">
                                            <div className="author-name fw-bold">
                                                <h4>
                                                    <Link
                                                        to={`/news/author/${blogDetails.writer.name}`}
                                                    >
                                                        {blogDetails.writer.name}
                                                    </Link>
                                                </h4>
                                            </div>

                                            <div className="flex items-center gap10">
                                                <div className="post-date">
                                                    <small>
                                                        {blogDetails.blog_details.publishDate}
                                                    </small>
                                                </div>
                                                <div className="bullet-icon">&#8226;</div>{' '}
                                                {/* This is the bullet icon */}
                                                <div className="time-to-read">
                                                    <small>
                                                        {blogDetails.blog_details.readTime}{' '}
                                                        {readTime}
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h1 className="main-post-title">{blogDetails.title}</h1>
                                <div className="blog-hero-image">
                                    <img
                                        className="w-full"
                                        src={blogDetails.blog_details.featuredImage}
                                        alt="hero-img"
                                    />
                                </div>
                            </div>
                            <div className="tags-share">
                                <div className={styles.tags}>
                                    {blogDetails.taxonomy.tags
                                        ? blogDetails.taxonomy.tags
                                              .slice(0, 3)
                                              .map(element => (
                                                  <BlogTag
                                                      key={element.id}
                                                      tagName={element.name}
                                                      tagSlug={element.slug}
                                                      cardType="Blogcard"
                                                  />
                                              ))
                                        : null}
                                </div>
                                <StyledDropdown ref={shareRef}>
                                    <ShareBtn
                                        onClick={handleClickShare}
                                        className="flex items-center share-link"
                                    >
                                        <FormattedMessage id="Blogs.ShareBlog.button" />{' '}
                                        <BsFillShareFill />
                                    </ShareBtn>

                                    {display && <ShareBlog blogDetails={blogDetails} />}
                                </StyledDropdown>
                            </div>

                            <div className="post-content relative">
                                <div className="blogContentHtml">
                                    {parse(blogDetails.content || '')}
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                        <IconSpinner />
                    </div>
                )}
                <RelatedBlogSection
                    title={<FormattedMessage id="Blogs.Related.title" />}
                    relatedBlogs={relatedBlogs}
                />
                <BecomePartner
                    title={<FormattedMessage id="Blogs.Contribute.heading" />}
                    linkto="/organizations"
                    btnText={<FormattedMessage id="Blogs.Contribute.button" />}
                    paraText={<FormattedMessage id="Blogs.Contribute.para" />}
                />
            </BlogPostSection>
            <Footer />
        </Page>
        // <>

        //     <div className={styles.blogDetailPage}>

        //         {relatedBlogs && relatedBlogs.length > 0 ? (
        //             <div className={styles.relatedBlogs}>
        //                 <AliceCarousel
        //                     buttonsDisabled={true}
        //                     autoPlayInterval={2000}
        //                     dotsDisabled={true}
        //                     infinite={false}
        //                     disableAutoPlayOnAction={true}
        //                     autoPlay={false}
        //                     responsive={{
        //                         0: { items: 1, itemsFit: 'contain' },
        //                         805: { items: 2, itemsFit: 'contain' },
        //                         1200: { items: 3, itemsFit: 'contain' },
        //                     }}
        //                     items={allRelatedBlogs}
        //                     ref={el => (CarouselListings = el)}
        //                 />
        //                 <button
        //                     className={styles.carouselBackButton}
        //                     onClick={() => CarouselListings.slidePrev()}
        //                 >
        //                     <FaChevronLeft className={styles.carouselChevron} />
        //                 </button>
        //                 <button
        //                     className={styles.carouselNextButton}
        //                     onClick={() => CarouselListings.slideNext()}
        //                 >
        //                     <FaChevronRight className={styles.carouselChevron} />
        //                 </button>
        //             </div>
        //         ) : null}
        //     </div>

        // </>
    );
};

const mapStateToProps = storeState => ({
    user: storeState.user.currentUser
        ? {
              initials: storeState.user.currentUser.attributes.profile.abbreviatedName,
              name:
                  storeState.user.currentUser.attributes.profile.firstName +
                  ' ' +
                  storeState.user.currentUser.attributes.profile.lastName,
              email: storeState.user.currentUser.attributes.email,
              firstName: storeState.user.currentUser.attributes.profile.firstName,
              lastName: storeState.user.currentUser.attributes.profile.lastName,
              cartCount: storeState.user.currentUser.cartCount,
              currentRole: storeState.user.currentUser.currentRole,
              activatedRoles: storeState.user.currentUser.activatedRoles,
          }
        : null,
    language: storeState.Languages.selectedLanguage.short,
    initLanguage: storeState.Languages.initLanguage,
});

export default connect(mapStateToProps, { changeLanguage })(withRouter(BlogDetailPage));
