import React, { useEffect, useState } from 'react';
import { FaLink } from 'react-icons/fa';
import { FormattedMessage } from 'react-intl';
import ReactMarkdown from 'react-markdown';
import { NamedLink } from '../../components';
import * as coreAPI from '../../coreApi';
import css from './ListingPage.css';
import SectionDescriptionMaybe from './SectionDescriptionMaybe';

const ListingProductContent = props => {
    const {
        maps,
        species,
        currentListing,
        title,
        activities,
        SectionHostMaybe,
        onContactUser,
        isEnquiryModalOpen,
        onCloseEnquiryModal,
        sendEnquiryError,
        sendEnquiryInProgress,
        onSubmitEnquiry,
        currentUser,
        onManageDisableScrolling,
        description,
        mountainsfull,
        development,
        enterprise,
        logoGuardians,
        logoAliados,
        organizationName,
        landscapeId,
    } = props;

    // const landscapeData = currentListing
    const [landscapeData, setLandScapeData] = useState(null);

    useEffect(() => {
        coreAPI.getLandScapeDetails(landscapeId).then(response => {
            console.log(response);
            setLandScapeData(response.data);
        });
    }, [landscapeId]);

    if (landscapeData === null) {
        return (
            <React.Fragment>
                <div></div>
            </React.Fragment>
        );
    }
    return (
        <React.Fragment>
            <div>
                {/*
      <SectionMapMaybe
        geolocation={geoCustom}
        publicData={publicData}
        listingId={currentListing.id}
      />
      <h2 className={css.sideTitle}>Información general</h2>
      <ul className={css.sideList}>
        <li className={css.sideListItem}><strong>Ubicación:</strong> {currentListing.attributes.publicData['Ubicacion']}</li>
        <li className={css.sideListItem}><strong>Coordenadas:</strong> {`${currentListing.attributes.publicData['latitud']}, ${currentListing.attributes.publicData['longitud']}`}</li>
        <li className={css.sideListItem}><strong>Superficie:</strong> {currentListing.attributes.publicData['Superficie']}</li>
        <li className={css.sideListItem}><strong>Altitud:</strong> {currentListing.attributes.publicData['Altitud']}</li>
        <li className={css.sideListItem}><strong>Ingreso:</strong> {currentListing.attributes.publicData['Ingreso']}</li>
        <li className={css.sideListItem}><strong>Aliado técnico:</strong> {currentListing.attributes.publicData['Aliado tecnico']}</li>
      </ul>
        */}
                <h2 className={css.sideTitle}>
                    <FormattedMessage id="LandscapeListing.location.title" />
                </h2>
                <img className={css.mapImage} src={maps[landscapeData.metadata.staticmaps]} />

                <h2 className={css.sideTitle}>
                    <FormattedMessage id="LandscapeListing.species.title" />
                </h2>
                <p className={css.sideSubTitle}>
                    <FormattedMessage id="LandscapeListing.species.subtitle" />
                </p>
                <div className={css.especiesList}>
                    {landscapeData.metadata.species.map((item, index) => (
                        <div key={index} className={css.sidebarBlocks}>
                            <img src={species[item]} className={css.especiesListPic} />
                            <p className={css.especiesListPicLabel}>
                                <FormattedMessage
                                    id={item}
                                    defaultMessage={item.replace(/([A-Z])/g, ' $1')}
                                />
                            </p>
                        </div>
                    ))}
                </div>
                <h2 className={css.sideTitle}>
                    <FormattedMessage id="LandscapeListing.activities.title" />
                </h2>
                <p className={css.sideSubTitle}>
                    <FormattedMessage id="LandscapeListing.activities.subtitle" />
                </p>
                <div className={css.especiesList}>
                    {landscapeData.metadata.activities.map((item, index) => (
                        <div key={index} className={css.sidebarBlocks}>
                            <img src={activities[item]} className={css.especiesListPic} />
                            <p className={css.especiesListPicLabel}>
                                <FormattedMessage
                                    id={item}
                                    defaultMessage={item.replace(/([A-Z])/g, ' $1')}
                                />
                            </p>
                        </div>
                    ))}
                </div>
                <SectionHostMaybe
                    title={title}
                    userData={landscapeData.userDetails}
                    listing={currentListing}
                    authorDisplayName={organizationName}
                    onContactUser={onContactUser}
                    isEnquiryModalOpen={isEnquiryModalOpen}
                    onCloseEnquiryModal={() => onCloseEnquiryModal}
                    sendEnquiryError={sendEnquiryError}
                    sendEnquiryInProgress={sendEnquiryInProgress}
                    onSubmitEnquiry={onSubmitEnquiry}
                    currentUser={currentUser}
                    onManageDisableScrolling={onManageDisableScrolling}
                />
            </div>
            <div className={css.mainContent}>
                <h2 className={css.firstTitle}>{landscapeData.metadata.slogan}</h2>
                <SectionDescriptionMaybe description={description} />
                <h2>
                    <FormattedMessage id="LandscapeListing.impact.title" />
                </h2>
                <div className={css.impact}>
                    <img src={mountainsfull} />
                    <div className={css.impactContent}>
                        <h3 className={css.impactTitle}>
                            <FormattedMessage id="LandscapeListing.impact.landscape.title" />
                        </h3>
                        <div>
                            <ReactMarkdown
                                source={landscapeData.metadata['paisaje']}
                                escapeHtml={false}
                            />
                        </div>
                        {/*
              <ul className={css.impactList}>
                <li><strong>{landscapeData.metadata['elaisaje']}</strong> hectáreas (hm2)</li>
                <li><strong>{landscapeData.metadata['especies']}</strong> especies</li>
                <li><strong>{landscapeData.metadata['hm2 de desforestación evitada']}</strong> hm2 de desforestación evitada</li>
              </ul>
            */}
                    </div>
                </div>
                <div className={css.impact}>
                    <img src={development} />
                    <div className={css.impactContent}>
                        <h3 className={css.impactTitle}>
                            <FormattedMessage id="LandscapeListing.impact.network.title" />
                        </h3>
                        <div>
                            <ReactMarkdown
                                source={landscapeData.metadata['red']}
                                escapeHtml={false}
                            />
                        </div>
                        {/*
              <ul className={css.impactList}>
                <li><strong>{currentListing.attributes.publicData['comunidades']}</strong> comunidades</li>
                <li><strong>{currentListing.attributes.publicData['concesionarios']}</strong> concecionarios</li>
              </ul>
            */}
                    </div>
                </div>
                <div className={css.impact}>
                    <img src={enterprise} />
                    <div className={css.impactContent}>
                        <h3 className={css.impactTitle}>
                            <FormattedMessage id="LandscapeListing.impact.ourImpact.title" />
                        </h3>
                        <div>
                            <ReactMarkdown
                                source={landscapeData.metadata['impact']}
                                escapeHtml={false}
                            />
                        </div>
                        {/*
              <ul className={css.impactList}>
                <li><strong>{currentListing.attributes.publicData['empresas y organizaciones']}</strong> empresas y organizaciones</li>
                <li><strong>{currentListing.attributes.publicData['personas']}</strong> personas</li>
              </ul>
            */}
                    </div>
                </div>

                <div className={css.partnerSection}>
                    <h2>
                        <FormattedMessage id="LandscapeListing.guardiansAndOrganization.title" />
                    </h2>
                    <ul className={css.logoList}>
                        {landscapeData.metadata.guardians.map((item, index) => (
                            <li key={index}>
                                <div className={css.logoListInnerWrapper}>
                                    <img
                                        className={css.logoListItemPic}
                                        src={logoGuardians[item]}
                                    />
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className={css.partnerSection}>
                    <h2>
                        <FormattedMessage id="LandscapeListing.allied.title" />
                    </h2>
                    {landscapeData.metadata.allies.length > 0 ? (
                        <ul className={css.logoList}>
                            {landscapeData.metadata.allies.length > 0 &&
                                landscapeData.metadata.allies.map((item, index) => (
                                    <React.Fragment key={index}>
                                        {logoAliados[item].id ? (
                                            <li className={css.logoSrcContainer}>
                                                <NamedLink
                                                    className={css.picLinksLogo}
                                                    name="ProfilePage"
                                                    params={{ slug: logoAliados[item].id }}
                                                >
                                                    <FaLink className={css.IconPic} />{' '}
                                                    <img
                                                        className={css.logoListItemPic}
                                                        src={logoAliados[item].image}
                                                    />
                                                </NamedLink>
                                            </li>
                                        ) : (
                                            <li>
                                                <div className={css.logoListInnerWrapper}>
                                                    <img
                                                        className={css.logoListItemPic}
                                                        src={logoAliados[item].image}
                                                    />
                                                </div>
                                            </li>
                                        )}
                                    </React.Fragment>
                                ))}
                        </ul>
                    ) : (
                        <h4>
                            <br />
                            <FormattedMessage id="LandscapeListing.allied.notFound" />
                        </h4>
                    )}
                </div>
            </div>
        </React.Fragment>
    );
};

export default ListingProductContent;
