import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { plans } from '../../util/paymentData';

import PlanBoxOption from '../../newComponents/PlanBox';

import { FormattedMessage, injectIntl } from 'react-intl';

import { getCart, createCart, updateCart } from '../../coreApi';
import { setSelectedPlan, setPlanTarget } from '../../ducks/subscriptionReducer.duck';

const PlansComparison = ({ recommendedPlan, user, userType, testId }) => {
    if (!userType) {
        userType = 'personas';
    }
    const history = useHistory();
    const googleAnalytics = process.env.REACT_APP_GOOGLE_ANALYTICS;
    const dispatch = useDispatch();
    const [loadingPlanId, setLoadingPlanId] = useState(null);
    const [cartPresent, setCartPresent] = useState(false);
    const [planCurrent, setPlanCurrent] = useState(false);
    const selectedPlan = useSelector(state => state.subscription.selectedPlan);

    const cartItem = {
        user: user && user.email + '_' + user.currentRole + '_' + user.cartCount,
        customer: {
            id: user && user.email,
            email_address: user && user.email,
            opt_in_status: true,
            first_name: user && user.firstName,
            last_name: user && user.lastName,
        },
        product: [
            {
                id: user && user.email,
                product_id:
                    selectedPlan && selectedPlan.plan == 'polen'
                        ? 'polen'
                        : `${selectedPlan.plan}_${selectedPlan.planType}`,
                product_variant_id:
                    selectedPlan && selectedPlan.plan == 'polen'
                        ? 'polen'
                        : `${selectedPlan.plan}_${selectedPlan.planType}`,
                quantity: 1,
                price: selectedPlan.planCost / 100,
            },
        ],
        checkout_url: 'https://www.regenera.earth/people',
    };

    const handleCartOperations = async (cartItem, cartPresent, setCartPresent) => {
        try {
            if (!cartPresent) {
                // Check if the cart exists
                const cartResponse = await getCart(cartItem.user);

                // Update the cart
                await updateCart({ user: cartItem.user, payload: cartItem.product });
                console.log('Cart updated:', cartResponse.data);

                // Mark the cart as present
                setCartPresent(true);
            } else {
                // Update the cart directly if it already exists
                const updateResponse = await updateCart({
                    user: cartItem.user,
                    payload: cartItem.product,
                });
                console.log('Cart updated:', updateResponse.data);
            }

            // After cart operations, navigate to the payment page
            return true; // Return true to indicate successful operation
        } catch (err) {
            // If cart doesn't exist, create a new one
            const createResponse = await createCart(cartItem);
            console.log('Cart created:', createResponse.data);

            // Mark the cart as present
            setCartPresent(true);

            // After cart operations, navigate to the payment page
            return true; // Return true to indicate successful operation
        }
    };

    useEffect(() => {
        if (Object.keys(selectedPlan).length > 0 && planCurrent) {
            if (user) {
                handleCartOperations(cartItem, cartPresent, setCartPresent)
                    .then(success => {
                        if (success) {
                            history.push('/subscription/signup'); // Navigate to payment page after successful cart operation
                        }
                    })
                    .catch(err => {
                        console.error('Error handling cart operations:', err);
                        // Handle error (e.g., show error message)
                    })
                    .finally(() => {
                        setLoadingPlanId(null); // Reset the loading state after the operation
                    });
            } else {
                history.push('/subscription/signup'); // Navigate to payment page after successful cart operation
            }
        }
    }, [selectedPlan, planCurrent]);

    const handlePlanSelection = selectedPlan => {
        setLoadingPlanId(selectedPlan.id);
        dispatch(setSelectedPlan(selectedPlan));
        dispatch(setPlanTarget(selectedPlan.planTarget));
        setPlanCurrent(true);
        if (selectedPlan) {
            googleAnalytics &&
                window.gtag('event', 'get_plan_info', {
                    send_to: 'G-34FQJZ2P9G',
                    plan_type: selectedPlan.planType,
                    plan: selectedPlan.plan,
                    price: selectedPlan.planCost / 100,
                });
        }
    };

    const filteredPlans = plans
        .filter(planDetails => planDetails.planTarget === userType)
        .sort((firstItem, secondItem) => firstItem.amount - secondItem.amount);

    const planDisplay = filteredPlans.map((plan, index) => {
        if (!plan) {
            return null;
        }
        const isRecommend = recommendedPlan ? recommendedPlan == plan.planType : false;

        return (
            <PlanBoxOption
                key={index}
                planBoxClass="plan-box"
                planImg={plan.planImage}
                planName={plan.planTitle}
                planInfo={plan.planDescription}
                planCost={plan.planCost / 300}
                planduration="/mo"
                data={plan.featureList}
                actionLinkText="Choose plan"
                handlePlanSelection={handlePlanSelection}
                plan={plan}
                isLoading={loadingPlanId === plan.id}
            />
        );
    });

    return <>{planDisplay}</>;
};

export default PlansComparison;
