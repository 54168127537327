import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { logout } from '../../../../../ducks/Auth.duck';
import { FormattedMessage } from 'react-intl';

const StyledLogoutButton = styled.button`
    border: none;
    width: 100%;
    text-align: left;
    padding: 20px;
    background: none;
    color: ${({ theme }) => theme.colors.title_green};

    cursor: pointer;

    &:hover {
        color: ${({ theme }) => theme.colors.title_green};
    }
`;

const LoginSubmenu = () => {
    const dispatch = useDispatch();
    const Loginsub = styled.div``;

    return (
        <div className="submenu dropdown-login">
            <ul>
                <li>
                    <Link to="/profile-check">
                        <FormattedMessage id="NewLandingPage.header.profileSettings" />
                    </Link>
                </li>
                <li>
                    <Link to="/account/tus-listings">
                        <FormattedMessage id="NewLandingPage.header.myLandscapes" />
                    </Link>
                </li>
                <li className="log-out">
                    <StyledLogoutButton onClick={() => dispatch(logout())}>
                        <FormattedMessage id="NewLandingPage.header.logout" />
                    </StyledLogoutButton>
                </li>
            </ul>
        </div>
    );
};

export default LoginSubmenu;
