import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

const MissionSubmenu = () => {
    return (
        <div className="submenu">
            <ul>
                <li>
                    <Link to="/organizations">
                        <FormattedMessage id="NewLandingPage.header.asOrganisation" />
                    </Link>
                </li>
                <li>
                    <Link to="/people">
                        <FormattedMessage id="NewLandingPage.header.asPerson" />
                    </Link>
                </li>
                <li>
                    <Link to="/land-manager">
                        <FormattedMessage id="NewLandingPage.header.asGuardian" />
                    </Link>
                </li>
            </ul>
        </div>
    );
};

export default MissionSubmenu;
