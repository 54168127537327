import React, { useEffect, useState, useMemo } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { useTheme } from 'styled-components';
import {
    Elements,
    CardCvcElement,
    CardExpiryElement,
    CardNumberElement,
} from '@stripe/react-stripe-js';
import { useStripe, useElements } from '@stripe/react-stripe-js';
// Create the Stripe object yourself...
import * as coreAPI from '../../coreApi';
import { injectIntl, FormattedMessage } from 'react-intl';
import { OrangeBtn } from '../../newComponents/Button/ButtonSkin';
import { Link, withRouter } from 'react-router-dom';
import CheckBoxInfo from '../../newComponents/checkbox';
import styled from 'styled-components';
import { IoIosInformationCircle } from 'react-icons/io';
import treePlan from '../../assets/newAssets/icons/tree.svg';
import landscapeIcon from '../../assets/icons/mountainsfull.svg';
import StepForm from './StepForm';

const StepFourSection = styled.section`
    margin-top: 60px;

    max-width: 730px;
    margin-left: auto;
    .form-n-summary {
        gap: 80px;
        .summary-box {
            border-radius: 8px;
            min-width: 240px;
            padding: 12px 16px;
            box-shadow: 3px 3px 20px 0px rgba(0, 0, 0, 0.07);
            h5 {
                line-height: 180%;
                font-weight: bold;
                margin-bottom: 12px;
            }
            .plan-detail {
                .plan-left {
                    padding-bottom: 12px;
                    border-bottom: 1px solid #ddedd0;
                    img {
                        width: 100%;
                        max-width: 64px;
                        margin-bottom: 7px;
                    }
                    .plan-title {
                        font-size: 16px;
                        line-height: 29px;
                        font-weight: 600;
                    }
                    .fee-in {
                        line-height: 180%;
                        .fee {
                            font-weight: 600;
                        }
                    }
                    .landscape-img {
                        max-width: 42px;
                        margin-top: 15px;
                        margin-bottom: 7px;
                    }
                }
                .plan-right {
                    padding-top: 12px;
                    .total-due {
                        .due-text {
                            font-weight: 600;
                            line-height: 180%;
                        }
                        .amount {
                            margin-top: 4px;
                            font-size: 32px;
                            line-height: 42px;
                            font-weight: 600;
                        }
                    }
                }
            }
        }
    }
    .form-section {
        gap: 12px;
        .card-num-box {
            font-size: 16px;
            height: 37px;
            width: 100%;
            padding: 10px 12px;
            border: 1px solid #cecece;
            border-radius: 6px;
            font-weight: 400;
            color: ${({ theme }) => theme.colors.title_green};
            &:focus {
                outline: 0;
            }
            &::placeholder {
                color: ${({ theme }) => theme.colors.lightGray};
            }
        }
        .expiry-box {
            flex: 1 0 65%;
        }
        .cvc-box {
            width: 100%;
        }

        .consent-box {
            margin-top: 8px;
        }
    }

    .cta {
        padding: 0 14px;
        max-width: 305px;
        margin-top: 40px;
        margin-bottom: 7px;
        text-align: center;
        button {
            width: 100%;
        }
        .powerby {
            box-shadow: none;
            width: 100%;
            top: 0;
            left: 0;
            gap: 2px;
            font-style: italic;
            max-width: none;
            .tooltip-ic {
                width: 20px;
                height: 20px;
            }
            .tooltip-box {
                bottom: 30px;
                font-style: normal;
            }
        }
    }
    @media (max-width: ${({ theme }) => theme.media.tab}) {
        .form-section {
            gap: 20px;
            padding: 0 14px;
            .two-col {
                flex-direction: row;
                justify-content: space-between;
                gap: 10px;
                .expiry-box {
                    max-width: none;
                    flex: 1 0 70%;
                }
            }
        }
        .cta {
            margin-top: 25px;
        }
        .form-n-summary {
            flex-direction: column;
            gap: 0;
            .summary-box {
                margin: 30px 0;
                h5 {
                    font-size: 19px;
                    line-height: 28px;
                    font-weight: bold;
                    padding-bottom: 8px;
                    border-bottom: 1px solid #ddedd0;
                }
                .plan-detail {
                    display: flex;
                    align-items: flex-start;
                    justify-content: space-between;
                    .plan-left {
                        padding-bottom: 0;
                        border-bottom: 0 none;
                        .plan-title {
                            font-size: 16px;
                            line-height: 29px;
                            font-weight: 600;
                        }
                        .fee {
                            font-weight: 600;
                        }
                    }
                    .plan-right {
                        padding-top: 0;
                        .total-due {
                            .due-text {
                                font-weight: 400;
                            }
                            .amount {
                                margin-top: 4px;
                                font-size: 28px;
                                line-height: 38px;
                                font-weight: 600;
                            }
                        }
                    }
                }
            }
            .right-side {
                order: -1;
            }
        }
    }
    @media (max-width: 850px) {
        max-width: none;
        width: 100%;
        .form-section {
            .two-col {
                .cvvbox {
                    max-width: none;
                    flex: 1 1 115px;
                }
            }
        }
    }
`;
const useOptions = () => {
    const theme = useTheme();
    const options = useMemo(() => ({
        showIcon: true,
        style: {
            base: {
                fontSize: '16px',
                color: theme.colors.title_green,
                letterSpacing: '0.025em',
                '::placeholder': {
                    color: theme.colors.lightGray,
                },
            },
        },
    }));

    return options;
};
const CheckoutForm = ({ planID, handlePaymentStatus }) => {
    const stripe = useStripe();
    const elements = useElements();
    const options = useOptions();
    const [paymentError, setPaymentError] = useState(false);
    const [tcCheck, setTcCheck] = useState(false);
    const [tcError, setTcError] = useState(false);
    const [isProcessing, setProcessingTo] = useState(false);
    const [name, setName] = useState('');
    const consentLabel = (
        <div
            dangerouslySetInnerHTML={{
                __html: "<small>Click to agree to <a href='#'>terms and conditions</a></small>",
            }}
        />
    );

    const onSubmit = async (values, actions) => {
        handlePaymentStatus(false);
        setProcessingTo(true);
        setPaymentError(false);
        setTcError(false);
        if (!tcCheck) {
            setTcError(true);
            setProcessingTo(false);
        }
        if (tcCheck) {
            try {
                if (!planID) {
                    return;
                }
                let token = { id: '' };
                let response = { token: {} };
                response = await stripe.createToken({ name });
                token = { ...token, ...response.token };
                coreAPI.subscribeToLandscapePlan(planID, token.id).then(async response => {
                    if (response.data) {
                        // if (response.data.status == 'active') {
                        //     updateSubscriptionPlan(response.data.subscription.subscriptionId);
                        //     handlePaymentStatus(true);
                        //     setPaymentError(false);
                        // } else {
                        //     handleDisable(false);
                        //     setPaymentError(true);
                        // }
                        console.log('rsponse.data', response.data);
                        if (response.data.status === 'requires_action') {
                            // We perform 3D Secure authentication
                            const { paymentIntent, error } = await stripe.confirmCardPayment(
                                response.data.clientSecret
                            );
                            if (error) return alert('Error in payment, please try again later');
                            if (paymentIntent.status === 'succeeded')
                                return alert(
                                    `Payment successful, payment ID - ${response.data.id}`
                                );
                            //updateSubscriptionPlan(response.data.subscription.subscriptionId);
                            handlePaymentStatus(true);
                            setPaymentError(false);
                            //const res2 = await axios.get(`http://localhost:5000/check/${res.data.id}`);
                            //alert(`Payment successful, payment ID - ${res.data.id}`);
                        } else if (response.data.status == 'active') {
                            //updateSubscriptionPlan(response.data.subscription.subscriptionId);
                            handlePaymentStatus(true);
                            setPaymentError(false);
                        } else {
                            setProcessingTo(false);
                            setPaymentError(true);
                        }
                    } else {
                        setProcessingTo(false);
                        setPaymentError(true);
                    }
                });
            } catch (e) {
                setProcessingTo(false);
                console.error(e);
                setPaymentError(true);
            }
        }
    };

    return (
        <form action="">
            <div className="form-section flex flex-col">
                <div className="input-box-section">
                    <label for="fname" className="small">
                        Cardholders name
                    </label>
                    {/* for disabled use class disabled */}
                    <input
                        name="cardname"
                        aria-describedby="firstnamehelp"
                        placeholder="Name"
                        className="form-control"
                        onChange={e => setName(e.target.value)}
                    />
                </div>
                <div className="input-box-section">
                    <label for="cardnum" className="small">
                        Card number
                    </label>
                    <div className="card-num-box">
                        <CardNumberElement options={options} />
                    </div>
                </div>
                <div className="two-col flex space-between">
                    <div className="input-box-section expiry-box">
                        <label for="expiry-date" className="small">
                            Expiry date
                        </label>
                        <div className="card-num-box">
                            <CardExpiryElement options={options} />
                        </div>
                    </div>
                    <div className="input-box-section cvc-box">
                        <label for="cvc" className="small">
                            CVC
                        </label>
                        <div className="card-num-box">
                            <CardCvcElement options={options} />
                        </div>
                    </div>
                </div>
                <div className="one-col">
                    <CheckBoxInfo
                        labelName={consentLabel}
                        labelId="consent"
                        checkboxSection="consent-box"
                        isChecked={tcCheck}
                        onChange={() => setTcCheck(!tcCheck)}
                    />
                </div>
                <div className="error-text small">
                    {!tcCheck && tcError && (
                        <FormattedMessage id="NewFriendsPage.Setup.Payment.tcErrMsg" />
                    )}
                </div>
            </div>
            <div className="cta mx-auto">
                <OrangeBtn type="submit" disabled={isProcessing || !stripe}>
                    <Link to={`/subscription/payment/success`}>Pay and join</Link>
                </OrangeBtn>
                <div className="powerby relative flex fee-n-box justify-center items-center small">
                    Powered by Stripe.
                    <Link className="tooltip-ic">
                        <IoIosInformationCircle />
                    </Link>
                    <div className="tooltip-box">
                        Your card details are safely secured within Stripe.
                    </div>
                </div>
            </div>
        </form>
    );
};

const MakePayment = ({ classname }) => {
    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

    const [paymentStatus, setPaymentStatus] = useState(false);

    return (
        <StepForm>
            <StepFourSection className={`flex flex-col justify-space-between ${classname}`}>
                <div className="form-n-summary flex">
                    <div className="left-side">
                        <div className="form-title">
                            Add payment details
                            <div className="title-info">
                                <div className="text-left hide-mobile">
                                    Your payment will be schedueled automatically every quarter on
                                    this card.
                                </div>
                                <div className="show-mobile text-center">
                                    Please provide us with your credit card or debit card details.
                                    Your payment will be schedueled automatically every month on
                                    this card.
                                </div>
                            </div>
                        </div>
                        <div className="summary-box show-tab">
                            <h5 className="small">Summary</h5>
                            <div className="plan-detail">
                                <div className="plan-left">
                                    <div className="plan-title">Tree plan</div>
                                    <div className="flex flex-col">
                                        <div className="fee small">$120 /mo</div>
                                    </div>
                                    <div className="small-txt small">Impact</div>
                                    <div className="big-txt flex items-center">
                                        10 hectares / year
                                    </div>
                                </div>

                                <div className="plan-right">
                                    <div className="total-due">
                                        <div className="due-text small">Total due per quarter</div>
                                        <div className="amount">$360</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Elements stripe={stripePromise}>
                            <CheckoutForm
                                planID={'planID'}
                                handlePaymentStatus={setPaymentStatus}
                            />
                        </Elements>
                    </div>
                    <div className="right-side">
                        <div className="summary-box hide-tab">
                            <h5 className="small">Summary</h5>
                            <div className="plan-detail">
                                <div className="plan-left">
                                    <img src={treePlan} className="hide-tab" alt="plan icon" />
                                    <div className="plan-title">Tree plan</div>
                                    <div className="flex fee-in justify-space-between">
                                        <div className="text small">Monthly fee</div>
                                        <div className="fee small">$120</div>
                                    </div>

                                    <div className="fee-in flex items-center justify-space-between">
                                        <div className="text small">Impact</div>
                                        <div className="fee small">10 hectares / year</div>
                                    </div>
                                </div>
                                <div className="plan-left">
                                    <img
                                        src={landscapeIcon}
                                        className="landscape-img hide-tab"
                                        alt="landscape icon"
                                    />
                                    <div className="plan-title">Paucartambo landscape</div>
                                </div>
                                <div className="plan-right">
                                    <div className="total-due">
                                        <div className="due-text small">Total due per quarter</div>
                                        <div className="amount">$360</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="step-nav flex mx-auto">
                    <div className="bullet"></div>
                    <div className="bullet"></div>
                    <div className="bullet"></div>
                    <div className="bullet current"></div>
                </div>
            </StepFourSection>
        </StepForm>
    );
};

//export default injectStripe(MakePayment);
export default injectIntl(withRouter(MakePayment));
