import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import Header from '../NewLandingPage/Sections/Header/Header';
import Footer from '../NewLandingPage/Sections/Footer/Footer';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Page } from '../../components';
import Button from '../../newComponents/Button';
import { OrangeBtn } from '../../newComponents/Button/ButtonSkin';

import HeroSection from '../../newComponents/HeroSection/HeroSection';
import friendsHeroDesktop from '../../assets/newAssets/friends/friends-hero-d.webp';
import friendsHeroMobile from '../../assets/newAssets/friends/friends-hero-m.webp';

import bgGreenTexture from '../../assets/newAssets/bg-texture-green.jpg';

import PlansComparison from '../../newComponents/PlansComparison';

import heroLeaf from '../../assets/newAssets/hero-leaf.png';

import FriendTestimonialContent from './Sections/FriendTestimonials';
import AllianceCompanies from './Sections/AllianceCompanies';
import ContactAction from './Sections/ContactAction';
import { FriendSection } from './Styles';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

const paras = [
    'Sign up to a membership plan that will enable you to do the right thing for Nature, climate, and communities.',
];
const pragraph = paras.map(para => <p>{para}</p>);

// featureData
const featureData = [
    {
        featureTitle: 'Measure',
        featureShort: 'Use our tools to:',
        featureList: ['Calculate your footprint'],
    },
    {
        featureTitle: 'Improve',
        featureShort: 'We enable you to:',
        featureList: [
            'Protect & restore <b>2.5</b> hectares',
            'Defend endangered species',
            'Compensate <b>20</b> tonnes GHG',
        ],
    },
    {
        featureTitle: 'Lead',
        featureShort: 'Work with us towards your:',
        featureList: ['Nature positive objectives'],
    },
];

const PlanContainer = styled.section`
    padding: 78px 4.8rem 110px;
    position: relative;
    &::before {
        content: ' ';
        background-image: url(${({ heroLeaf }) => heroLeaf});
        background-size: 100% auto;
        width: 104px;
        height: 178px;
        position: absolute;
        right: 0;
        top: -60px;
    }
    .title-box {
        margin-bottom: 60px;
    }
    .plan-group {
        gap: 20px;
        .plan-box {
            max-width: 415px;
            width: 100%;
            border: 2px solid #f3f2f2;
            &:hover {
                border: 2px solid #6ea44c;
            }
        }
        .plan-box-active {
            border: 2px solid #6ea44c;
        }
    }
    .showPlans {
        display: flex;
    }
    .hidePlans {
        display: none;
    }

    @media (max-width: 1175px) {
        padding: 32px 20px 50px;
        &::before,
        &::after {
            content: none;
        }
        .title-box {
            margin-bottom: 20px;
        }
    }
    @media (max-width: ${({ theme }) => theme.media.mobile}) {
        .plan-group {
            flex-wrap: wrap;
            gap: 10px;
        }
    }
`;

const FriendsPage = ({ location, history, user }) => {
    const [planType, setPlanType] = useState('personas');
    // featureData
    const featureDataPollen = [
        {
            featureList: [
                'Footprint reports & status in platform',
                'Monthly updates from your landscape',
                'Digital trimestral impact reports',
                '<b>1,000</b> m2 of land protected',
                '<b>1,000</b> kg of CO2 emissions compensated per year',
                '<b>1</b> Tree planted and cared for per year',
            ],
        },
    ];
    const featureDataSeed = [
        {
            featureList: [
                'Footprint reports & status in platform',
                'Monthly updates from your landscape',
                'Digital trimestral impact reports',
                '<b>5,000</b> m2 of land protected',
                '<b>5,0000</b> kg of CO2 emissions compensated per year',
                '<b>1</b> Tree planted and cared for per year',
            ],
        },
    ];

    const featureDataTree = [
        {
            featureList: [
                'Footprint reports & status in platform',
                'Monthly updates from your landscape',
                'Digital trimestral impact reports',
                '<b>10,000</b> m2 of land protected',
                '<b>10,000</b> kg of CO2 emissions compensated per year',
                '<b>2</b> Trees planted and cared for per year',
            ],
        },
    ];

    const featureDataForest = [
        {
            featureList: [
                'Footprint reports & status in platform',
                'Monthly updates from your landscape',
                'Digital trimestral impact reports',
                '<b>25,000</b> m2 of land protected',
                '<b>25,0000</b> kg of CO2 emissions compensated per year',
                '<b>4</b> Trees planted and cared for per year',
            ],
        },
    ];

    const featureDataLandscape = [
        {
            featureList: [
                'Footprint reports & status in platform',
                'Monthly updates from your landscape',
                'Digital trimestral impact reports',
                '<b>100,000</b> m2 of land protected',
                '<b>100,0000</b> kg of CO2 emissions compensated per year',
                '<b>10</b> Trees planted and cared for per year',
            ],
        },
    ];

    return (
        <Page>
            <Header currentPath={location.pathname} user={user} history={history} />
            <FriendSection bgGreenTexture={bgGreenTexture}>
                <HeroSection
                    title="Want to help out as an individual? Become a"
                    titleline="Friend!"
                    titleclass="text-white"
                    paratext={pragraph}
                    paraclass="text-white"
                    heroBg="green-bg"
                    heroImgM={friendsHeroMobile}
                    heroImgD={friendsHeroDesktop}
                    alttxt="Friends"
                    orangeBtn
                    btnText="Sign up"
                    btnLink="#"
                />

                <PlanContainer heroLeaf={heroLeaf}>
                    <div className="title-box text-center">
                        <h3>Membership plans</h3>
                    </div>

                    <div className="title-box text-center">
                        <Button
                            onClick={() => setPlanType('personas')}
                            variant={planType === 'personas' ? 'filled' : 'invertedGhost'}
                            testId="btn-friend-personal-plan"
                        >
                            <FormattedMessage id="NewFriendsPage.Plans.Intro.PersonalBtn" />
                        </Button>
                        <Button
                            variant={planType === 'familia' ? 'filled' : 'invertedGhost'}
                            onClick={() => setPlanType('familia')}
                            testId="btn-friend-family-plan"
                        >
                            <FormattedMessage id="NewFriendsPage.Plans.Intro.FamilyBtn" />
                        </Button>
                    </div>
                    <Link className="text-center panel-link" to="/organizations">
                        Looking for Regenera for organisations?
                    </Link>

                    <div
                        className={`plan-group flex justify-center ${
                            planType === 'personas' ? 'showPlans' : 'hidePlans'
                        }`}
                    >
                        <PlansComparison user={user} userType={planType} />
                    </div>

                    <div
                        className={`plan-group flex justify-center ${
                            planType === 'familia' ? 'showPlans' : 'hidePlans'
                        }`}
                    >
                        <PlansComparison user={user} userType={planType} />
                    </div>

                    <div className="center-text-btn flex flex-col text-center">
                        <h3>Need help choosing a plan?</h3>
                        <p>Calculate your footprint to match your impact.</p>
                        <OrangeBtn>
                            <Link to="/calculator">Calculate footprint</Link>
                        </OrangeBtn>
                    </div>
                </PlanContainer>
                <FriendTestimonialContent />
                <AllianceCompanies />
            </FriendSection>
            <Footer history={history} />
        </Page>
    );
};

const mapStateToProps = storeState => ({
    user: storeState.user.currentUser
        ? {
              initials: storeState.user.currentUser.attributes.profile.abbreviatedName,
              name:
                  storeState.user.currentUser.attributes.profile.firstName +
                  ' ' +
                  storeState.user.currentUser.attributes.profile.lastName,
              email: storeState.user.currentUser.attributes.email,
              firstName: storeState.user.currentUser.attributes.profile.firstName,
              lastName: storeState.user.currentUser.attributes.profile.lastName,
              currentRole: storeState.user.currentUser.currentRole,
              cartCount: storeState.user.currentUser.cartCount,
          }
        : null,
});
export default connect(mapStateToProps)(withRouter(FriendsPage));
