import React from 'react';
import { OrangeBtn } from '../../newComponents/Button/ButtonSkin';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import SelectPlanBox from '../../newComponents/SelectPlanBox';
import { IoIosInformationCircle } from 'react-icons/io';
import seedPlan from '../../assets/newAssets/icons/leaf.svg';
import treePlan from '../../assets/newAssets/icons/tree.svg';
import forestPlan from '../../assets/newAssets/icons/forest.svg';
import mapIcon from '../../assets/newAssets/icons/map-icon.svg';
import cottonIcon from '../../assets/newAssets/icons/cotton-icon.svg';
import leafIcon from '../../assets/newAssets/icons/leaf-icon.svg';
import StepForm from './StepForm';

const StepTwoSection = styled.section`
    margin-top: 60px;
    max-width: 630px;
    .fee-n-box {
        .fee {
            line-height: 180%;
        }
        .tooltip-box {
            top: 35px;
            right: 0;
            background-color: white;
            z-index: 9;
        }
    }
    .plan-group {
        gap: 20px;
        .planradio {
            position: relative;
            .radio-box {
                .checkmark {
                    right: 8px;
                    left: auto;
                    top: 8px;
                }
                .plan-box {
                    max-width: 196px;
                    position: relative;
                    top: -18px;
                    &:hover {
                        border: 1px solid ${({ theme }) => theme.colors.light_green};
                    }
                }
                input:checked + .plan-box {
                    border: 1px solid #6ea44c;
                }
            }
        }
        /* Add hover effect for the checkmark */
        .planradio:hover .checkmark {
            border: 1px solid ${({ theme }) => theme.colors.light_green};
        }
    }
    .cta {
        padding: 0 14px;
        max-width: 305px;
        margin-top: 20px;
        margin-bottom: 7px;
        text-align: center;
        button {
            width: 100%;
        }
    }
    @media (max-width: ${({ theme }) => theme.media.tab}) {
        .fee-n-box {
            margin-left: auto;
            margin-right: auto;
            .tooltip-box {
                bottom: 35px;
                top: auto;
            }
        }
        .plan-group {
            flex-wrap: wrap;
            gap: 20px;
            .planradio {
                position: relative;
                .radio-box {
                    .checkmark {
                        right: 8px;
                        left: auto;
                        top: 8px;
                    }
                    .plan-box {
                        position: relative;
                        top: -18px;
                    }
                }
            }
        }
    }
    @media (max-width: 500px) {
        .plan-group {
            .planradio {
                .radio-box {
                    .plan-box {
                        max-width: none;
                    }
                }
            }
        }
    }
`;

const ConfirmPlan = ({ classname }) => {
    const SeedPlanList = [
        { impactIc: `${mapIcon}`, listTxt: '2.5 hectares' },
        { impactIc: `${cottonIcon}`, listTxt: '25 tonnes CO2' },
        { impactIc: `${leafIcon}`, listTxt: '4 Trees ' },
    ];

    const TreePlanList = [
        { impactIc: `${mapIcon}`, listTxt: '10 hectares' },
        { impactIc: `${cottonIcon}`, listTxt: '100 tonnes CO2' },
        { impactIc: `${leafIcon}`, listTxt: '10 Trees ' },
    ];

    const ForestPlanList = [
        { impactIc: `${mapIcon}`, listTxt: '25 hectares' },
        { impactIc: `${cottonIcon}`, listTxt: '250 tonnes CO2' },
        { impactIc: `${leafIcon}`, listTxt: '40 Trees ' },
    ];

    return (
        <StepForm>
            <StepTwoSection className={`mx-auto flex flex-col ${classname}`}>
                <div className="fee-n-box w-full box-shadow absolute hide-tab">
                    <div className="fee flex items-center justify-space-between">
                        <div className="small-txt small">Monthly fee</div>
                        <div className="big-txt flex items-center small">
                            $120{' '}
                            <Link className="tooltip-ic">
                                <IoIosInformationCircle />
                            </Link>
                            <div className="tooltip-box">
                                As much as we love Stripe to secure our payment system, we want your
                                money to go to Nature in stead of them. As they charge for every
                                transaction, we try to limit those by billing quarterly.
                            </div>
                        </div>
                    </div>
                    <div className="fee flex items-center justify-space-between">
                        <div className="small-txt small">Impact</div>
                        <div className="big-txt flex items-center small">10 hectares / year</div>
                    </div>
                </div>
                <div className="form-title">
                    Select your plan
                    <div className="title-info text-center show-mobile">
                        Pick how you want to support.
                    </div>
                </div>
                <form action="">
                    <div className="plan-group flex justify-center">
                        <div className="planradio">
                            <label for="planOne" className="radio-box">
                                <input id="planOne" name="selectplan" type="radio" value="" />
                                <SelectPlanBox
                                    planBoxClass="plan-box w-full"
                                    planImg={seedPlan}
                                    planName="Seed plan"
                                    planInfo="For start-ups who do not own premises or vehicles and a team up to 10 people. "
                                    planCost="$30"
                                    listTitle="Impact:"
                                    planduration="/mo"
                                    actionLink="#"
                                    actionLinkText="View all features"
                                    data={SeedPlanList}
                                />
                                <span className="checkmark"></span>
                            </label>
                        </div>

                        <div className="planradio">
                            <label for="planTwo" className="radio-box">
                                <input id="planTwo" name="selectplan" type="radio" value="" />
                                <SelectPlanBox
                                    planBoxClass="plan-box"
                                    planImg={treePlan}
                                    planName="Tree plan"
                                    planInfo="For micro organisations with own premises and a team up to 20 people."
                                    planCost="$120"
                                    listTitle="Impact:"
                                    planduration="/mo"
                                    actionLink="#"
                                    actionLinkText="View all features"
                                    data={TreePlanList}
                                />
                                <span className="checkmark"></span>
                            </label>
                        </div>

                        <div className="planradio">
                            <label for="planThree" className="radio-box">
                                <input id="planThree" name="selectplan" type="radio" value="" />
                                <SelectPlanBox
                                    planBoxClass="plan-box"
                                    planImg={forestPlan}
                                    planName="Forest plan"
                                    planInfo="For small organisations with own premises and vehicles and a team up to 50 people."
                                    planCost="$300"
                                    listTitle="Impact:"
                                    planduration="/mo"
                                    actionLink="#"
                                    actionLinkText="View all features"
                                    data={ForestPlanList}
                                />
                                <span className="checkmark"></span>
                            </label>
                        </div>
                    </div>
                    <div className="fee-n-box w-full box-shadow relative show-tab">
                        <div className="fee flex items-center justify-space-between">
                            <div className="small-txt small">Monthly fee</div>
                            <div className="big-txt flex items-center small">
                                $120{' '}
                                <Link className="tooltip-ic">
                                    <IoIosInformationCircle />
                                </Link>
                                <div className="tooltip-box">
                                    As much as we love Stripe to secure our payment system, we want
                                    your money to go to Nature in stead of them. As they charge for
                                    every transaction, we try to limit those by billing quarterly.
                                </div>
                            </div>
                        </div>
                        <div className="fee flex items-center justify-space-between">
                            <div className="small-txt small">Impact</div>
                            <div className="big-txt flex items-center small">
                                10 hectares / year
                            </div>
                        </div>
                    </div>
                    <div className="cta mx-auto">
                        <OrangeBtn>Select and continue</OrangeBtn>
                    </div>
                </form>
                <div className="step-nav flex mx-auto">
                    <div className="bullet"></div>
                    <div className="bullet current"></div>
                    <div className="bullet"></div>
                    <div className="bullet"></div>
                </div>
            </StepTwoSection>
        </StepForm>
    );
};

export default ConfirmPlan;
