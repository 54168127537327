import React, { useState } from 'react';
import styled from 'styled-components';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';

import Header from '../NewLandingPage/Sections/Header/Header';
import Footer from '../NewLandingPage/Sections/Footer/Footer';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Page } from '../../components';
import { OrangeBtn } from '../../newComponents/Button/ButtonSkin';
import CheckBoxInfo from '../../newComponents/checkbox';
import leftLeafSignup from '../../assets/newAssets/left-leaf-signup.webp';
import rightLeafSignup from '../../assets/newAssets/right-leaf-signup.webp';
import titleBorderLine from '../../assets/newAssets/title-border-line.svg';
import bkgImage from '../../assets/mainhero.jpg';
import { GoogleLogin } from 'react-google-login';
import { FaRegEye } from 'react-icons/fa';
import { FaRegEyeSlash } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const LoginSection = styled.section`
    .left-section {
        width: 100%;
        max-width: 36%;
        padding: 60px 30px 70px 80px;
        background-color: ${({ theme }) => theme.colors.title_green};
        background-image: url(${({ leftLeafSignup }) => leftLeafSignup});
        background-repeat: no-repeat;
        background-position: left bottom;

        background-image: url(${({ leftLeafSignup }) => leftLeafSignup}),
            url(${({ bkgImage }) => bkgImage});
        background-repeat: no-repeat, no-repeat;
        background-position: left bottom, -950px 0;

        h1 {
            font-size: 48px;
            font-weight: 500;
            line-height: 58px;
        }
        .border {
            position: relative;
            img {
                position: absolute;
                bottom: -3px;
                left: 0;
                width: 100%;
            }
        }
        .circle-box {
            width: 300px;
            height: 300px;
            border-radius: 50%;
            border: 3px solid white;
            overflow: hidden;
            bottom: 10px;
            left: 70px;
            img {
                max-width: 100%;
                object-fit: cover;
            }
        }
    }
    .googleSignUp {
        width: 100%;
        margin: 20px auto;
        text-align: center;
    }
    .googleSignUp h5 {
        font-weight: 500;
        font-size: 18px;
        line-height: 23px;
        color: #000000;
    }
    .googleSignUp > button {
        width: 100%;
        border-radius: 15px;
    }
    .form-box {
        flex: 1 1 auto;
        padding: 80px 5% 70px;
        box-sizing: content-box;
        position: relative;
        max-width: 420px;
        .form-title {
            font-size: 19px;
            line-height: 27px;
            font-weight: 600;
            margin-bottom: 27px;
        }
        .form-section {
            gap: 16px;
        }
        .cta {
            padding: 0 14px;
            max-width: 305px;
            margin-top: 20px;
            margin-bottom: 7px;
            text-align: center;
            button {
                width: 100%;
            }
            .login-cta {
                line-height: 150%;
                margin-top: 27px;
                a {
                    font-weight: 600;
                    color: ${({ theme }) => theme.colors.title_green};
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
    @media (max-width: ${({ theme }) => theme.media.tab}) {
        .left-section {
            max-width: 100%;
            background-image: url(${({ rightLeafSignup }) => rightLeafSignup});
            background-position: right -40px;
            padding: 28px 10px;
            margin-top: 65px;
            h1 {
                font-size: 42px;
                line-height: 53px;
                text-align: center;
                br {
                    display: none;
                }
            }
        }
        .form-box {
            padding-top: 15px;
            .form-title {
                font-size: 16px;
                line-height: 180%;
                margin-bottom: 10px;
            }
            .cta {
                .login-cta {
                    margin-top: 10px;
                }
            }
        }
        .form-section {
            gap: 24px;
        }
    }
`;
const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID || null;
const Login = ({ location, history, user }) => {
    const [showPassword, setShowPassword] = useState(false);

    const togglepassword = () => {
        setShowPassword(!showPassword);
    };

    const consentLabel = (
        <div
            dangerouslySetInnerHTML={{
                __html:
                    '<small>I agree to receive a monthly newsletter and can unsubscribe at any time.',
            }}
        />
    );

    // styled

    return (
        <Page>
            <Header currentPath={location.pathname} user={user} history={history} />
            <LoginSection
                className="flex flex-wrap"
                leftLeafSignup={leftLeafSignup}
                rightLeafSignup={rightLeafSignup}
                bkgImage={bkgImage}
            >
                <div className="left-section relative">
                    <h1 className="text-white">
                        Lets protect Nature <br />
                        <span class="border">
                            together
                            <img src={titleBorderLine} alt="border" />
                        </span>
                    </h1>
                </div>
                <div className="form-box mx-auto flex flex-col">
                    <div className="form-title text-center">Log in</div>
                    <form action="">
                        <div className="form-section flex flex-col">
                            <div className="one-col">
                                <div className="input-box-section">
                                    <label for="email" className="small">
                                        E-mail
                                    </label>
                                    <input
                                        name="email"
                                        type="email"
                                        aria-describedby="emailhelp"
                                        placeholder="E-mail"
                                        className="form-control"
                                    />
                                </div>
                            </div>
                            <div className="one-col">
                                <div className="input-box-section">
                                    <label for="password" className="small">
                                        Password
                                    </label>

                                    <div class="input-group relative">
                                        <div
                                            onClick={togglepassword}
                                            class="input-group-prepend absolute"
                                        >
                                            {showPassword ? <FaRegEye /> : <FaRegEyeSlash />}
                                        </div>
                                        <input
                                            name="password"
                                            type={showPassword ? 'text' : 'password'}
                                            aria-describedby="passwordhelp"
                                            placeholder="Password"
                                            className="form-control"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="cta mx-auto">
                            <OrangeBtn className="disabled">Log in</OrangeBtn>
                            <div className="login-cta small">
                                Forgot password? <Link to="/signup">Reset Password</Link>
                            </div>
                            <div className="googleSignUp">
                                <h5>or</h5>
                                {googleClientId && (
                                    <GoogleLogin
                                        className="login-w-google flex items-center justify-center"
                                        clientId={googleClientId}
                                        disabled={false}
                                        buttonText={
                                            <FormattedMessage id="NewPartnersPage.Setup.Account.GoogleBtn" />
                                        }
                                        onSuccess={''}
                                        onFailure={''}
                                        cookiePolicy={'single_host_origin'}
                                    />
                                )}
                            </div>

                            <div className="login-cta small">
                                Don't have an account yet? <Link to="/signup">Sign up</Link>
                            </div>
                        </div>
                    </form>
                </div>
            </LoginSection>
            <Footer history={history} />
        </Page>
    );
};

const mapStateToProps = storeState => ({
    user: storeState.user.currentUser
        ? {
              initials: storeState.user.currentUser.attributes.profile.abbreviatedName,
              name:
                  storeState.user.currentUser.attributes.profile.firstName +
                  ' ' +
                  storeState.user.currentUser.attributes.profile.lastName,
          }
        : null,
});
export default connect(mapStateToProps)(withRouter(Login));
