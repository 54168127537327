import React from 'react';
import { ErrorMessage, useField } from 'formik';

const TextField = ({ label, ...props }) => {
    const [field, meta] = useField(props);
    return (
        <>
            {label ? (
                <label htmlFor={field.name} className="small">
                    {label}
                </label>
            ) : (
                <></>
            )}
            <input
                className={`form-control shadow-none `}
                {...field}
                {...props}
                autoComplete="off"
            />
            <ErrorMessage component="span" name={field.name} className="error-text small" />
        </>
    );
};

export default TextField;
