import React from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';

import SliderReview from 'react-slick';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { DarkGreenBtn, LightGreenBtn } from '../../../../newComponents/Button/ButtonSkin';
import { ExternalLink, NamedLink } from '../../../../components';

import testimonial_img1 from '../../../../assets/newAssets/testimonials/martin_romero.png';
import testimonial_img2 from '../../../../assets/newAssets/testimonials/joaquin_randall.png';
import testimonial_img3 from '../../../../assets/newAssets/testimonials/sergio_carrillo.png';

const Testimonials = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,

        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: true,
                    className: 'center',
                    arrows: false,
                    dots: true,
                    speed: 300,
                    centerPadding: '35px',
                    infinite: true,
                },
            },
        ],
    };

    const TestimonialSection = styled.section`
        background: rgba(221, 237, 208, 0.16);
        padding: 125px 4.8rem 93px;
        text-align: center;

        .two-cols {
            max-width: 100%;
            flex: 1;
            gap: 120px;
            .testimonal-slider {
                box-shadow: 2px 4px 15px rgba(0, 0, 0, 0.05);
                border-radius: 8px;
                width: 100%;
                max-width: 480px;
                .slide-box {
                    border-radius: 8px;
                    background-color: white;
                    padding: 40px 66px 66px;
                    box-sizing: border-box;
                    border-radius: 8px;

                    .profile-info-parent {
                        gap: 18px;
                        .profile-img {
                            img {
                                border-radius: 50%;
                                border: 3.353px solid ${({ theme }) => theme.colors.light_green};
                                max-width: 160px;
                            }
                        }
                        .profile-info-quote {
                            font-size: 19px;
                            line-height: 1.3;
                            color: ${({ theme }) => theme.colors.title_green};
                            font-weight: 600;
                            gap: 5px;
                            .profile-name {
                                font-size: 16px;
                                font-weight: 400;
                                color: ${({ theme }) => theme.colors.grey};
                            }
                        }
                    }
                }
            }
            .testimonal-information {
                gap: 12px 0;
                text-align: left;
                max-width: 446px;
                .parent-info {
                    gap: 32px;
                    .testimonial-text {
                        line-height: 180%;
                        color: ${({ theme }) => theme.colors.title_green};
                    }
                    .group-buttons-info {
                        gap: 12px 0;
                        .group-btns {
                            gap: 0 12px;
                            .action-btn {
                                padding: 0 35px;
                                width: 100%;
                                display: block;
                                text-wrap: nowrap;
                            }
                        }
                        .button-info {
                            a {
                                font-weight: bold;
                                color: ${({ theme }) => theme.colors.title_green};
                                transition: all 0.3s ease;
                                -webkit-transition: all 0.3s ease 0s;
                                -moz-transition: all 0.3s ease 0s;
                                -o-transition: all 0.3s ease 0s;

                                &:hover {
                                    color: ${({ theme }) => theme.colors.light_green};
                                }
                            }
                        }
                    }
                }
            }
        }
        @media (max-width: ${({ theme }) => theme.media.tab}) {
            padding: 24px 20px 60px;
            .two-cols {
                flex-wrap: wrap;
                gap: 40px;
                .testimonal-slider {
                    max-width: none;
                    order: 1;
                    box-shadow: none;

                    .slick-slider {
                        margin-left: -20px;
                        margin-right: -20px;
                    }
                    .slick-slide > div {
                        margin: 0 8px;
                    }
                    .slide-box {
                        box-shadow: 2px 4px 15px rgba(0, 0, 0, 0.05);
                        padding: 20px 16px 20px;
                        .profile-info-parent {
                            // display: inline-block;
                            .profile-img {
                                margin-bottom: 8px;
                            }
                            .profile-info-quote {
                                display: inline-block;
                                .profile-quote {
                                    margin-bottom: 4px;
                                }
                            }
                        }
                    }
                    .slick-center {
                        .profile-name {
                            margin-top: 6px;
                        }
                    }
                }
                .testimonal-information {
                    order: 0;
                    text-align: center;
                    gap: 8px;
                    .parent-info {
                        gap: 24px;
                        .group-buttons-info {
                            .group-btns {
                                flex-direction: column;
                                gap: 8px;
                                .action-btn {
                                    margin: 0 auto;
                                    &:first-child {
                                        max-width: 211px;
                                    }
                                    &:last-child {
                                        max-width: 197px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    `;

    return (
        <TestimonialSection>
            <div className="two-cols flex justify-center items-center">
                <div className="testimonal-slider">
                    <SliderReview {...settings}>
                        {/* slider one */}
                        <div className="slide-box">
                            <div className="profile-info-parent flex flex-col">
                                <div className="profile-img mx-auto">
                                    <img alt="profile img" src={testimonial_img1} />
                                </div>
                                <div className="profile-info-quote flex flex-col">
                                    <div className="profile-quote">
                                        <FormattedMessage id="NewLandingPage.testimonials.quote.martinromero" />
                                    </div>
                                    <div className="profile-name">
                                        <FormattedHTMLMessage
                                            id="NewLandingPage.testimonials.designation.martinromero"
                                            values={{
                                                strong: chunks => <strong>{chunks}</strong>,
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="slide-box">
                            <div className="profile-info-parent flex flex-col">
                                <div className="profile-img mx-auto">
                                    <img src={testimonial_img2} alt="profile img" />
                                </div>
                                <div className="profile-info-quote flex flex-col">
                                    <div className="profile-quote">
                                        <FormattedMessage id="NewLandingPage.testimonials.quote.joaquinrandall" />
                                    </div>
                                    <div className="profile-name">
                                        <FormattedHTMLMessage
                                            id="NewLandingPage.testimonials.designation.joaquinrandall"
                                            values={{
                                                strong: chunks => <strong>{chunks}</strong>,
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="slide-box">
                            <div className="profile-info-parent flex flex-col">
                                <div className="profile-img mx-auto">
                                    <img src={testimonial_img3} alt="profile img" />
                                </div>
                                <div className="profile-info-quote flex flex-col">
                                    <div className="profile-quote">
                                        <FormattedMessage id="NewLandingPage.testimonials.quote.sergiocarrillo" />
                                    </div>
                                    <div className="profile-name">
                                        <FormattedHTMLMessage
                                            id="NewLandingPage.testimonials.designation.sergiocarrillo"
                                            values={{
                                                strong: chunks => <strong>{chunks}</strong>,
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </SliderReview>
                </div>
                <div className="testimonal-information flex flex-col items-start">
                    <h3>
                        <FormattedMessage id="NewLandingPage.testimonials.title" />
                    </h3>
                    <div className="parent-info flex flex-col">
                        <div className="testimonial-text">
                            <FormattedMessage id="NewLandingPage.testimonials.description" />
                        </div>
                        <div className="group-buttons-info flex flex-col">
                            <div className="group-btns flex justify-space-between">
                                <DarkGreenBtn className="action-btn">
                                    <Link to="/organizations">
                                        <FormattedMessage id="NewLandingPage.testimonials.becomePartner" />
                                    </Link>
                                </DarkGreenBtn>
                                <LightGreenBtn className="action-btn">
                                    <ExternalLink href="https://calendly.com/frankhajek/30min">
                                        <FormattedMessage id="NewLandingPage.testimonials.scheduleCall" />
                                    </ExternalLink>
                                </LightGreenBtn>
                            </div>
                            <div className="button-info">
                                <FormattedMessage id="NewLandingPage.testimonials.business" />{' '}
                                <Link to="/people">
                                    <FormattedMessage id="NewLandingPage.testimonials.person" />
                                </Link>{' '}
                                <FormattedMessage id="NewLandingPage.testimonials.orAsA" />{' '}
                                <Link to="/land-manager">
                                    <FormattedMessage id="NewLandingPage.testimonials.landManager" />
                                </Link>
                                !
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </TestimonialSection>
    );
};

export default Testimonials;
