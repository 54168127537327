import React from 'react';
import CompanyLogos from '../../../../newComponents/CompanyLogos';

import idb from '../../../../assets/newAssets/company-logo/idb.png';
import unrestoration from '../../../../assets/newAssets/company-logo/un-restoration-logo.png';
import cbb from '../../../../assets/newAssets/company-logo/certified-b-corp.png';
import ncc from '../../../../assets/newAssets/company-logo/natural-capital-coalition.png';
import peruco from '../../../../assets/newAssets/company-logo/peru_co.png';

const sliderDataTop = [
    { id: 1, img: `${idb}` },
    { id: 2, img: `${unrestoration}` },
    { id: 3, img: `${cbb}` },
    { id: 4, img: `${ncc}` },
    { id: 5, img: `${peruco}` },
];

const slidersettingOne = {
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 0,
    speed: 8000,
    pauseOnHover: true,
    cssEase: 'linear',
    arrows: false,
    infinite: true,
    variableWidth: true,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                dots: false,
            },
        },
    ],
};
const slidersettingTwo = {
    ...slidersettingOne,
    speed: 10000,
};

const AllianceCompanies = () => {
    return (
        <CompanyLogos
            title="Proud of our work practices and alliances:"
            settingsOne={slidersettingOne}
            dataOne={sliderDataTop}
        />
    );
};

export default AllianceCompanies;
