import React from 'react';
import { Link } from 'react-router-dom';

import StepSlider from 'react-slick';
import { OrangeBtn } from '../../../../newComponents/Button/ButtonSkin';

import ImageWithText from '../../../../newComponents/ImageWithText';
import downBendedArrow from '../../../../assets/newAssets/down-bended-arrow.svg';
import workStep1 from '../../../../assets/newAssets/workstep1.webp';
import workStep2 from '../../../../assets/newAssets/workstep2.webp';
import workStep3 from '../../../../assets/newAssets/workstep3.webp';

import { HowitWorksSection } from './Styles';

const stepSettings = {
    responsive: [
        {
            breakpoint: 9999,
            settings: 'unslick',
        },
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: true,
                dots: true,
                speed: 300,
                infinite: false,
                adaptiveHeight: true,
            },
        },
    ],
};

const listingdata = [
    { textintro: 'Choose your role and multiply your impact as a:' },
    {
        strongText: 'Product partner:',
        textinfo:
            'incorporate Regenera into your products to deliver greater value to your clients.',
    },
    {
        strongText: 'Impact partner:',
        textinfo: 'develop public goods and commons in collaboration with Regenera',
    },
    {
        strongText: 'Landscape partner:',
        textinfo: 'implement  incentives for Regenera land managers to protect and restore nature.',
    },
];

const lastStepData = listingdata.map(list => {
    return (
        <>
            {list.textintro && <p>{list.textintro}</p>}
            <div className="li-list">
                <strong>{list.strongText}</strong> {list.textinfo}
            </div>
        </>
    );
});

const HowitWorks = ({ data }) => {
    return (
        <HowitWorksSection>
            <div className="title-box text-center">
                <h2>How it works</h2>
                <img src={downBendedArrow} className="hide-mobile arrow-img" alt="arrow" />
            </div>
            <div className="steps-how mx-auto relative">
                <StepSlider {...stepSettings}>
                    <ImageWithText
                        section="step-row"
                        imageDesktop={workStep1}
                        imgWithClass="img-text"
                        contentBox="text-section"
                        imgBoxClass="picture-box"
                        smallTitle="Step 1"
                        firstTitle="Choose your regenerative Plan and the Landscape you wish to support"
                        text="It's time to measure your footprint. If you do not yet know your carbon or environmental footprint, or would like it updated, use our Calculator or schedule a call with one of our Expert advisors."
                    />
                    <ImageWithText
                        section="step-row"
                        imageDesktop={workStep2}
                        imgWithClass="img-text"
                        contentBox="text-section"
                        imgBoxClass="picture-box"
                        smallTitle="Step 2"
                        firstTitle="Improve your operational performance & reduce your footprint"
                        text="It's time to measure your footprint. If you do not yet know your carbon or environmental footprint, or would like it updated, use our Calculator or schedule a call with one of our Expert advisors."
                    />
                    <ImageWithText
                        section="step-row"
                        imageDesktop={workStep3}
                        imgWithClass="img-text"
                        contentBox="text-section"
                        imgBoxClass="picture-box"
                        smallTitle="Step 3"
                        firstTitle="Lead the way towards Nature & Climate positive action"
                        text={lastStepData}
                    />
                </StepSlider>
                <div className="faq-link">
                    Want to learn more? <Link to="/faq">Check our FAQ</Link>
                </div>
            </div>
            <div className="center-text-btn flex flex-col text-center">
                <h2>Have you checked your footprint yet?</h2>
                <OrangeBtn className="btnFootprint">
                    <Link to="/organisation/calculator">Calculate your organisation footprint</Link>
                </OrangeBtn>
            </div>
        </HowitWorksSection>
    );
};

export default HowitWorks;
