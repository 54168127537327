import styled from 'styled-components';

export const HeroContainer = styled.section`
    padding-left: 4.8rem;
    gap: 80px;
    position: relative;

    &.green-bg {
        background: #004532 url(${({ bgGreenTexture }) => bgGreenTexture}) no-repeat center top;
        background-size: cover;
        margin-bottom: -4px;
        .text-white,
        .text-white p {
            color: white;
        }
        .right-section {
            margin-bottom: -4px;
        }
        .cta {
            margin-top: 10px;
        }
    }
    @media (max-width: ${({ theme }) => theme.media.tab}) {
        margin-top: 66px;
        padding: 25px 0 35px;
        gap: 30px;
        &.green-bg {
            margin-bottom: 0;
            padding-bottom: 0;
            gap: 0;
            .cta {
                margin-top: 25px;
                position: relative;
            }
            .right-section {
                padding-left: 0;
                margin-bottom: -5px;
                margin-top: -20px;
            }
        }
    }
    @media (max-width: ${({ theme }) => theme.media.mobile}) {
        flex-direction: column;
    }
`;
export const LeftSection = styled.section`
    max-width: 522px;
    .title-box {
        margin-bottom: 16px;
        h1 {
            font-size: 48px;
            line-height: 58px;
            .border {
                position: relative;
                img {
                    position: absolute;
                    bottom: -3px;
                    left: 0;
                    width: 100%;
                }
            }
        }
    }
    .content-box {
        p {
            margin-top: 16px;
            color: ${({ theme }) => theme.colors.text_color};
        }
    }
    @media (max-width: ${({ theme }) => theme.media.tab}) {
        max-width: 100%;
        padding: 0 20px;
        .title-box {
            margin-bottom: 12px;
            h1 {
                font-size: 38px;
                line-height: 52px;
                br {
                    display: none;
                }
                .border {
                    img {
                        width: 100%;
                    }
                }
            }
        }
    }
`;
export const RightSection = styled.section`
    @media (max-width: ${({ theme }) => theme.media.tab}) {
        padding-left: 20px;
        min-width: 40%;
        @media (max-width: ${({ theme }) => theme.media.mobile}) {
            min-width: 100%;
        }
    }
`;
